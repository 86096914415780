import React from 'react';
import { IntlShape } from 'react-intl';
import { Column } from 'react-table';

import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';

import {
    EntranceFeePayload,
    EntranceFeeStrategy,
    RestaurantEntranceFeePayload,
    ShiftEntranceFeePayloadData,
} from '@/services/innovorder/entranceFee/entranceFee.type';
import { Shift } from '@/services/innovorder/shift/shift.type';
import { computeDaysOfTheWeek, computeTime } from '@/pages/Brand/Grant/GrantForm/GrantShifts/utils';

type RowsParam = {
    shiftEntranceFees: ShiftEntranceFeePayloadData[];
    shifts: Shift[];
    defaultValues: { amount: number; vat: number };
    intl: IntlShape;
    handleOnChange: (
        shiftId: string,
        attributes: 'amount' | 'vat' | 'isEnabled',
        value: number | null,
        shift: { name: string; isDefault: boolean },
    ) => void;
};

export const computeEntranceFeeShiftsColumns = (intl: IntlShape): Column<TableRow>[] => {
    return [
        {
            Header: computeText(intl, 'entranceFee.configuration.table.activation'),
            accessor: 'isEnabled',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'entranceFee.configuration.table.name'),
            accessor: 'name',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'entranceFee.configuration.table.time'),
            accessor: 'time',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'entranceFee.configuration.table.days'),
            accessor: 'daysOfWeek',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'entranceFee.configuration.table.amount'),
            accessor: 'amount',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'entranceFee.configuration.table.vat'),
            accessor: 'vat',
            disableSortBy: true,
        },
    ];
};

export const computeEntranceFeeShiftsRows = ({
    shifts,
    shiftEntranceFees,
    defaultValues,
    intl,
    handleOnChange,
}: RowsParam): TableRow[] => {
    return shifts.map((shift) => {
        const enabledEntranceFee = shiftEntranceFees.find(
            (shiftEntranceFee) => shiftEntranceFee.shiftId === shift.shiftId,
        );

        const amount = enabledEntranceFee ? enabledEntranceFee.amount : defaultValues.amount;
        const vat = enabledEntranceFee ? enabledEntranceFee.vat : defaultValues.vat;

        const computeAmountError = (value: number) => {
            if (Number.isNaN(value)) {
                return computeText(intl, 'shift.amount.required');
            }

            if (value < 0) {
                return computeText(intl, 'shift.amount.positive');
            }

            return undefined;
        };

        const computeVatError = (value: number | null) => {
            if (Number.isNaN(value)) {
                return computeText(intl, 'shift.vat.required');
            }

            if (value && value < 0) {
                return computeText(intl, 'shift.vat.positive');
            }

            return undefined;
        };

        return {
            isEnabled: {
                type: 'switch',
                value: {
                    value: !!enabledEntranceFee,
                    disabled: false,
                    onChange: () => {
                        handleOnChange(shift.shiftId, 'isEnabled', null, {
                            name: shift.name,
                            isDefault: shift.isDefault,
                        });
                    },
                },
            },
            name: {
                type: 'string',
                value: {
                    text: shift.isDefault
                        ? computeText(intl, 'entranceFee.configuration.table.defaultShift')
                        : shift.name,
                },
            },
            time: {
                type: 'computedNumber',
                value: { text: computeTime({ start: shift.start, end: shift.end }), number: shift.start },
            },
            daysOfWeek: { type: 'string', value: { text: computeDaysOfTheWeek(intl, shift.daysOfWeek) } },
            amount: !enabledEntranceFee
                ? { type: 'disabled', value: false }
                : {
                      type: 'input',
                      value: {
                          unitType: 'currency',
                          value: (amount / CURRENCY_RATE).toString(),
                          min: 0,
                          type: 'number',
                          error: computeAmountError(amount),
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                              const { value } = event.target;
                              handleOnChange(enabledEntranceFee.shiftId, 'amount', parseFloat(value) * CURRENCY_RATE, {
                                  name: shift.name,
                                  isDefault: shift.isDefault,
                              });
                          },
                      },
                  },
            vat: !enabledEntranceFee
                ? { type: 'disabled', value: false }
                : {
                      type: 'input',
                      value: {
                          unitType: 'percentage',
                          value: vat ? (vat / CURRENCY_RATE).toString() : undefined,
                          type: 'number',
                          min: 0,
                          error: computeVatError(vat),
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                              const { value } = event.target;
                              handleOnChange(enabledEntranceFee.shiftId, 'vat', parseFloat(value) * CURRENCY_RATE, {
                                  name: shift.name,
                                  isDefault: shift.isDefault,
                              });
                          },
                      },
                  },
        };
    });
};

export const buildEntranceFee = ({ entranceFee }: { entranceFee: EntranceFeePayload }): EntranceFeePayload => {
    return {
        ...entranceFee,
        amount: entranceFee.amount * CURRENCY_RATE,
        vat: entranceFee.vat,
    };
};

export const buildEntranceFeeRestaurants = ({
    entranceFee,
    updatedRestaurant,
    updatedShiftEntranceFees,
}: {
    entranceFee: EntranceFeePayload;
    updatedRestaurant: {
        restaurantId: number;
        restaurantName: string;
        strategy: EntranceFeeStrategy;
        dailyLimit: number;
    };
    updatedShiftEntranceFees: ShiftEntranceFeePayloadData[];
}): RestaurantEntranceFeePayload[] => {
    const isFirstActivation = !entranceFee.restaurants.some(
        ({ restaurantId }) => restaurantId === updatedRestaurant.restaurantId,
    );

    const filteredRestaurants =
        updatedShiftEntranceFees.length === 0
            ? entranceFee.restaurants.filter((restaurant) => restaurant.restaurantId !== updatedRestaurant.restaurantId)
            : entranceFee.restaurants;

    const updatedRestaurants = filteredRestaurants.map((restaurant) => {
        return restaurant.restaurantId === updatedRestaurant.restaurantId
            ? {
                  ...restaurant,
                  restaurant_entrance_fee: {
                      ...restaurant.restaurant_entrance_fee,
                      // should we not update amount, ceiling and threshold here ? to keep consistency if FF is deactivated ?
                      strategy: updatedRestaurant.strategy,
                      dailyLimit: updatedRestaurant.dailyLimit,
                      status: 'customised',
                  },
                  shiftEntranceFees: updatedShiftEntranceFees.map(({ shiftId, amount, vat, shift }) => {
                      return {
                          entranceFeeId: entranceFee.entranceFeeId,
                          restaurantId: updatedRestaurant.restaurantId,
                          shiftId,
                          shift,
                          amount,
                          vat,
                      };
                  }),
              }
            : restaurant;
    });

    if (isFirstActivation) {
        updatedRestaurants.push({
            restaurantId: updatedRestaurant.restaurantId,
            name: updatedRestaurant.restaurantName,
            restaurant_entrance_fee: {
                entranceFeeId: entranceFee.entranceFeeId,
                restaurantId: updatedRestaurant.restaurantId,
                vat: entranceFee.vat,
                amount: entranceFee.amount * CURRENCY_RATE,
                // should we not keep ceiling and threshold here ? to keep consistency if FF is deactivated ?
                strategy: updatedRestaurant.strategy,
                dailyLimit: updatedRestaurant.dailyLimit,
                status: 'customised',
            },
            shiftEntranceFees: updatedShiftEntranceFees.map(({ shiftId, amount, vat, shift }) => {
                return {
                    entranceFeeId: entranceFee.entranceFeeId,
                    restaurantId: updatedRestaurant.restaurantId,
                    shift,
                    shiftId,
                    amount,
                    vat,
                };
            }),
        });
    }

    return updatedRestaurants;
};

export const areShiftEntranceFeesValid = (shiftEntranceFees: ShiftEntranceFeePayloadData[]): boolean => {
    let isValid = true;

    shiftEntranceFees.forEach((shiftEntranceFee) => {
        if (
            Number.isNaN(shiftEntranceFee.amount) ||
            shiftEntranceFee.amount < 0 ||
            (shiftEntranceFee.vat && shiftEntranceFee.vat < 0)
        ) {
            isValid = false;
        }
    });

    return isValid;
};
