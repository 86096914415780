import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse, ApiResponseBase } from '../request.types';
import {
    BaseGuestGroupAttributes,
    CreateGuestGroupsPayload,
    DeleteGuestGroupPayload,
    GetGuestGroupByIdPayload,
    GetGuestGroupsPayload,
    GuestGroup,
    GuestGroupWithGuestsCount,
    RelatedGuestsCount,
    UpdateGuestGroupsPayload,
    UploadGuestGroupsErrorsResponse,
    UploadGuestGroupsPayload,
    UploadGuestGroupsResponse,
    GuestGroupMapByDepth,
} from './guestGroup.type';
import { getGuestGroupMapByDepth } from './utils';

const guestGroupApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getGuestGroups: build.query<GuestGroupWithGuestsCount[], ApiRequest<GetGuestGroupsPayload>>({
            query: ({ brandId, token, ...optionalFilters }) => ({
                url: `/guest_groups`,
                params: { brandId, ...optionalFilters },
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroupWithGuestsCount[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getGuestGroupMapByDepth: build.query<GuestGroupMapByDepth, ApiRequest<GetGuestGroupsPayload>>({
            query: ({ brandId, token }) => ({
                url: `/guest_groups?brandId=${brandId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GuestGroupWithGuestsCount[]>): GuestGroupMapByDepth => {
                const { data: guestGroups } = response;
                return getGuestGroupMapByDepth(guestGroups);
            },
            extraOptions: { maxRetries: 3 },
        }),
        postGuestGroup: build.mutation<GuestGroup, ApiRequest<CreateGuestGroupsPayload>>({
            query: ({ name, brandId, pricingRules, parentGuestGroupId, depth, token }) => ({
                url: `/guest_groups`,
                method: 'POST',
                params: { brandId },
                body: {
                    name,
                    pricingRules,
                    parentGuestGroupId,
                    depth,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroup>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putGuestGroup: build.mutation<GuestGroup, ApiRequest<UpdateGuestGroupsPayload>>({
            query: ({ name, groupId, pricingRules, parentGuestGroupId, depth, token }) => ({
                url: `/guest_groups`,
                method: 'PUT',
                params: { groupId },
                body: {
                    name,
                    pricingRules,
                    parentGuestGroupId,
                    depth,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroup>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getGuestGroupById: build.query<BaseGuestGroupAttributes, ApiRequest<GetGuestGroupByIdPayload>>({
            query: ({ groupId, token }) => ({
                url: `/guest_groups/${groupId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroup>) => {
                const { name, pricingRules, depth, parentGuestGroupId, brandId } = response.data;
                return {
                    name,
                    pricingRules: pricingRules.map(({ pricingRuleId }) => pricingRuleId),
                    depth,
                    parentGuestGroupId,
                    brandId,
                };
            },
            extraOptions: { maxRetries: 3 },
        }),
        deleteGuestGroup: build.mutation<ApiResponseBase, ApiRequest<DeleteGuestGroupPayload>>({
            query: ({ guestGroupId, token }) => ({
                url: `/guest_groups`,
                method: 'DELETE',
                params: { groupId: guestGroupId },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            extraOptions: { maxRetries: 3 },
        }),
        getRelatedGuestsCount: build.mutation<RelatedGuestsCount, ApiRequest<GetGuestGroupByIdPayload>>({
            query: ({ groupId, token }) => ({
                url: `/guest_groups/${groupId}/count_related_guests`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            extraOptions: { maxRetries: 3 },
            transformResponse: (response: ApiResponse<number>) => {
                const guestsCount = response.data;
                return {
                    count: guestsCount,
                };
            },
        }),
        uploadGuestGroups: build.mutation<UploadGuestGroupsResponse, ApiRequest<UploadGuestGroupsPayload>>({
            query: ({ brandId, formData, token }) => ({
                url: 'guest_groups/import',
                params: { brandId },
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            transformResponse: (response: ApiResponse<UploadGuestGroupsResponse>) => response.data,
            transformErrorResponse: (response: ApiResponse<UploadGuestGroupsErrorsResponse[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetGuestGroupsQuery,
    useLazyGetGuestGroupsQuery,
    usePostGuestGroupMutation,
    usePutGuestGroupMutation,
    useGetGuestGroupByIdQuery,
    useDeleteGuestGroupMutation,
    useGetRelatedGuestsCountMutation,
    useUploadGuestGroupsMutation,
    useGetGuestGroupMapByDepthQuery,
} = guestGroupApi;
