import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import React from 'react';
import { Input } from '@/components/atoms/Form/Input';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { BadgeInputButton, BadgeInputContainer } from './BadgeInput.style';
import { Badge } from '@/services/innovorder/customer/customer.types';
import { BaseCustomerAttributes } from '../../List/CreateCustomerModal/CreateCustomerModal.types';
import { theme } from '@/core/theme';
import { SvyTooltip } from '@/components/savory/SvyTooltip';
import { SvyIcon } from '@/components/savory/SvyIcon';

const ICON_SIZE = 16;

type TextFieldValues = Record<string, string>;

type BadgeInputProps = {
    rules?: RegisterOptions<TextFieldValues, keyof TextFieldValues>;
    badge: Badge & { index: number };
    deleteBadge: (index: number) => void;
    starBadge: (index: number) => void;
};

const BadgeInput: React.FunctionComponent<React.PropsWithChildren<BadgeInputProps>> = ({
    badge,
    deleteBadge,
    starBadge,
}) => {
    const { control } = useFormContext<BaseCustomerAttributes>();
    const intl = useIntl();
    return (
        <Controller
            key={badge.index}
            control={control}
            name={`badges.${badge.index}`}
            rules={{
                validate: (value) => {
                    if (value.value === '') {
                        return computeText(intl, 'field.required');
                    }
                },
            }}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
                return (
                    <BadgeInputContainer>
                        <Input
                            error={error?.message}
                            value={value.value}
                            onChange={(e) => onChange({ ...value, value: e.target.value })}
                            onBlur={onBlur}
                            name={`badges.${badge.index}`}
                            placeholder={`${computeText(intl, 'customer.form.badgeNumber', { index: '' })} `}
                            labelId={`${computeText(intl, 'customer.form.badgeNumber', {
                                index: badge.index > 0 ? badge.index + 1 : '',
                            })} `}
                            ref={ref}
                            style={{ flex: 1, marginRight: theme.savory.spacing[3] }}
                            type="number"
                        />
                        {badge.index > 0 && (
                            <Input
                                error={error?.message}
                                value={value.type}
                                onChange={(e) => onChange({ ...value, type: e.target.value })}
                                onBlur={onBlur}
                                name={`badges.${badge.index}`}
                                placeholder={`${computeText(intl, 'customer.form.badgeType.placeholder')} `}
                                labelId={`${computeText(intl, 'customer.form.badgeType')} `}
                                ref={ref}
                                style={{ flex: 1 }}
                                type="text"
                            />
                        )}

                        <div style={{ display: 'flex', alignItems: 'center', padding: '12px', cursor: 'pointer' }}>
                            {badge.isDefault ? (
                                <SvyIcon icon="star-fill" style={{ color: theme.savory.colors.warning[400] }} />
                            ) : (
                                <SvyTooltip tooltipContent={computeText(intl, 'customer.form.badge.default')}>
                                    <SvyIcon
                                        onClick={() => {
                                            starBadge(badge.index);
                                        }}
                                        icon="star-line"
                                    />
                                </SvyTooltip>
                            )}
                        </div>
                        {badge.index > 0 && (
                            <BadgeInputButton onClick={() => deleteBadge(badge.index)}>
                                <SVGIcon
                                    data-testid={`badge.input.remove`}
                                    icon={SVGIcons.TRASH}
                                    color="red"
                                    size={ICON_SIZE}
                                />
                            </BadgeInputButton>
                        )}
                    </BadgeInputContainer>
                );
            }}
        />
    );
};

export default BadgeInput;
