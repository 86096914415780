import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PermissionDenied from '@/pages/PermissionDenied';
import { routes } from '@/core/router/routes';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { Table } from '@/components/atoms/Table';
import RestaurantSelector from '@/components/molecules/RestaurantSelector';
import { Button } from '@/components/atoms/Button';
import { getAssertedUserBrandId } from '@/redux/user';
import OptionsModal from '@/pages/Brand/Customer/OptionsModal';
import { SvyIcon } from '@/components/savory/SvyIcon';
import { useCustomersTableVM } from '@/pages/Brand/Customer/Customer.viewmodel';
import { useCustomersListVM } from './List.viewmodel';
import CreateCustomerModal from './CreateCustomerModal';
import {
    Container,
    HeaderContainer,
    Header,
    HeaderTitle,
    HeaderRight,
    Subtitle,
    ActionsContainer,
    OptionsButton,
} from './List.style';

const ListCustomer: React.FunctionComponent<React.PropsWithChildren> = () => {
    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'customers'), []);
    const brandId = useSelector(getAssertedUserBrandId);

    const {
        shouldUseNewGuestJourneyDesign,
        guestGroups,
        activeStudentEntities,
        onHideCreateModal,
        handleImport,
        handleExport,
    } = useCustomersListVM({ brandId });
    const {
        headers,
        rows,
        isLoading,
        handleRowClick,
        fetchData,
        fetchDataTotalCount,
        buttonLabel,
        selectedRestaurantId,
        restaurants,
        handleRestaurantChange,
        defaultHiddenColumns,
        showOptionsModal,
        handleOpenOptionModal,
        handleCloseOptionModal,
        customerFetchOptions,
        handleCustomerFetchOptionsChange,
    } = useCustomersTableVM({ brandId });

    return shouldUseNewGuestJourneyDesign ? (
        <RouterNavigationTabs routes={tabRoutes}>
            {guestGroups && (
                <CreateCustomerModal
                    title="customer.create.modalTitle"
                    isOpen
                    onHide={onHideCreateModal}
                    guestGroups={guestGroups}
                    activeStudentEntities={activeStudentEntities}
                />
            )}
            <OptionsModal
                isOpen={showOptionsModal}
                onHide={handleCloseOptionModal}
                brandId={brandId}
                customerFetchOptions={customerFetchOptions}
                handleCustomerFetchOptionsChange={handleCustomerFetchOptionsChange}
            />
            <Container>
                <ActionsContainer>
                    <Button buttonType="primary" onClick={handleImport}>
                        <FormattedMessage id="brand.customers.action.import" />
                    </Button>
                    <Button buttonType="primary" onClick={handleExport}>
                        <FormattedMessage id="brand.customers.action.export" />
                    </Button>
                </ActionsContainer>
                <HeaderContainer>
                    <Header>
                        <HeaderTitle>
                            <Title text="brand.customers.page.title" />
                            <Subtitle>
                                <Text text="brand.customers.page.description" />
                            </Subtitle>
                        </HeaderTitle>
                        <HeaderRight>
                            <OptionsButton onClick={handleOpenOptionModal} buttonType="secondary">
                                <SvyIcon icon="filter-line" />
                                <FormattedMessage id="customers.options" />
                            </OptionsButton>
                            {restaurants && (
                                <RestaurantSelector
                                    buttonLabel={buttonLabel}
                                    restaurants={restaurants}
                                    selected={selectedRestaurantId}
                                    onChange={handleRestaurantChange}
                                    headerLabel="orderList.filters.restaurantHeader"
                                />
                            )}
                        </HeaderRight>
                    </Header>
                    <Table
                        isSearchable
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={headers}
                        hasPagination
                        hasUrlNavigation
                        isSortable
                        onRowClick={handleRowClick}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        fetchDataTotalCount={fetchDataTotalCount}
                        columnSelectorId="customerList"
                        defaultHiddenColumns={defaultHiddenColumns}
                    />
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    ) : (
        <PermissionDenied />
    );
};

export default ListCustomer;
