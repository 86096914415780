import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { getUserToken } from '@/redux/user';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Badge, ICustomerAttributes, UpdateCustomerV2Type } from '@/services/innovorder/customer/customer.types';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { PaymentConfiguration } from '@/services/innovorder/brand/brand.type';
import { Switch } from '@/components/form/Switch';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import {
    useAddBadgeMutation,
    useDeleteBadgeMutation,
    useUpdateBadgeMutation,
    useUpdateCustomerMutation,
} from '@/services/innovorder/customer/customer.endpoints';
import { getCustomerDefaultValues, getErrorMessage, prepareUpdateCustomerPayload } from './Student.utils';
import {
    ActionBar,
    ActionContainer,
    Container,
    ErrorMessage,
    Left,
    Right,
    Row,
    Subtitle,
    SwitchRow,
} from './Student.style';
import UpdateInfoForm from './UpdateInfoForm';
import PricingRuleForm from './PricingRuleForm';
import BadgeForm from './BadgeForm';
import { BadgeFormValues } from './BadgeForm/Modal';
import EwalletOrverdraftForm from './EwalletOrverdraftForm/EwalletOrverdraftForm';

export type StudentProps = {
    customer: ICustomerAttributes;
    guestGroups: GuestGroupWithGuestsCount[];
    brandPaymentConfiguration?: PaymentConfiguration | null;
    refetch: () => void;
};

const Student: React.FunctionComponent<React.PropsWithChildren<StudentProps>> = ({
    customer,
    guestGroups,
    brandPaymentConfiguration,
    refetch,
}) => {
    const token = useSelector(getUserToken);
    const [errorMessage, setErrorMessage] = useState('');
    const [badgeErrorMessage, setBadgeErrorMessage] = useState('');

    const [updateCustomer, { isLoading, error, reset }] = useUpdateCustomerMutation();
    const [deleteBadge, { isLoading: isLoadingDeleteBadge }] = useDeleteBadgeMutation();
    const [addBadge, { isLoading: isLoadingAddBadge, error: errorAddBadge, reset: resetAddBadge }] =
        useAddBadgeMutation();
    const [updateBadge, { isLoading: isLoadingEditBadge, error: errorUpdateBadge, reset: resetUpdateBadge }] =
        useUpdateBadgeMutation();

    const customerForm: UseFormReturn<UpdateCustomerV2Type> = useForm<UpdateCustomerV2Type>({
        defaultValues: { ...getCustomerDefaultValues(customer, guestGroups) },
        mode: 'all',
    });

    const onCancel = () => {
        setErrorMessage('');
        customerForm.reset();
    };

    const onSave = async () => {
        setErrorMessage('');
        await updateCustomer({
            ...prepareUpdateCustomerPayload(customer, customerForm.getValues()),
            token,
        });
        await refetch();
    };

    const onSaveBadge = async (badge: BadgeFormValues) => {
        const data = {
            badgeId: badge.badgeId,
            type: badge.type,
            value: badge.value,
            isDefault: badge.isDefault,
            customerId: customer.customerId,
            isBlocked: badge.isBlocked || false,
            token,
        };
        const response = data.badgeId ? await updateBadge(data) : await addBadge(data);
        if ('data' in response) {
            await refetch();
        }
        return response;
    };

    const onDeleteBadge = async (badge: Badge) => {
        const data = {
            badgeId: badge.badgeId,
            customerId: badge.customerId,
            token,
        };
        await deleteBadge(data);
        await refetch();
    };

    const onResetErrorMessage = () => {
        setBadgeErrorMessage('');
        reset();
        resetAddBadge();
        resetUpdateBadge();
    };

    useEffect(() => {
        if (errorAddBadge || errorUpdateBadge)
            setBadgeErrorMessage(
                getErrorMessage([
                    error as FetchBaseQueryError,
                    errorAddBadge as FetchBaseQueryError,
                    errorUpdateBadge as FetchBaseQueryError,
                ]),
            );
    }, [errorAddBadge, errorUpdateBadge]);

    useEffect(() => {
        if (error) setErrorMessage(getErrorMessage([error as FetchBaseQueryError]));
    }, [error]);

    useEffect(() => {
        customerForm.reset(getCustomerDefaultValues(customer, guestGroups));
    }, [customer, guestGroups]);

    return (
        <FormProvider {...customerForm}>
            <Container>
                <Row>
                    <Left>
                        <Title text="customer.student.info.title" />
                        <Subtitle>
                            <Text text="customer.student.info.desctiption" />
                        </Subtitle>
                    </Left>
                    <Right>
                        <UpdateInfoForm brandId={customer.brandId} />
                        <BadgeForm
                            onSave={onSaveBadge}
                            onDelete={onDeleteBadge}
                            onResetErrorMessage={onResetErrorMessage}
                            badges={customer.badges}
                            errorMessage={badgeErrorMessage}
                            isLoading={isLoadingAddBadge || isLoadingDeleteBadge || isLoadingEditBadge}
                        />
                    </Right>
                </Row>
                <Row>
                    <Left>
                        <SwitchRow>
                            <Title text="customer.student.pricing.rules.title" />
                        </SwitchRow>
                        <Subtitle>
                            <Text text="customer.student.pricing.rules.desctiption" />
                        </Subtitle>
                    </Left>
                    <Right>
                        <PricingRuleForm brandId={customer.brandId} />
                    </Right>
                </Row>
                {brandPaymentConfiguration?.useEwalletOverdraft && <EwalletOrverdraftForm />}
                <Row>
                    <Left>
                        <SwitchRow>
                            <Title text="customer.student.badge.title" />
                            <Switch name={'isBadgeBlocked'} key={'isBadgeBlocked'} />
                        </SwitchRow>
                        <Subtitle>
                            <Text text="customer.student.badge.description" />
                        </Subtitle>
                    </Left>
                </Row>
                <ActionBar>
                    <ActionContainer>
                        {errorMessage && <ErrorMessage text={errorMessage} weight="medium" />}
                        <Button buttonType="red" onClick={onCancel} disabled={false}>
                            <FormattedMessage id="button.cancel" />
                        </Button>

                        <Button buttonType="primary" onClick={onSave} isLoading={isLoading} disabled={isLoading}>
                            <FormattedMessage id="button.save" />
                        </Button>
                    </ActionContainer>
                </ActionBar>
            </Container>
        </FormProvider>
    );
};

export default Student;
