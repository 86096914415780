import { generateBadgeNumber } from '@/pages/Customer/CustomerFormInputs/CreateCustomerModal.utils';
import { ACCOUNT_PAYMENT_TYPE, CreateCustomerPayload } from '@/services/innovorder/customer/customer.types';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { getAccountType } from '@/services/innovorder/customer/customer.utils';
import { BaseCustomerAttributes } from './CreateCustomerModal.types';

export const prepareCreateCustomerPayload = (
    formValues: BaseCustomerAttributes,
    brandId: number,
): CreateCustomerPayload => {
    const { accountPaymentType } = formValues;
    const role = 'customer';

    // remove the id from the badges because it's a field generated by the useFieldArray hook and it is not needed in the payload
    formValues.badges.forEach((badge) => delete badge.id);
    return { ...formValues, ...getAccountType(accountPaymentType), brandId, role };
};

const generateCustomerEmailDefaultValue = (badgeNumber: string): string => {
    return `${badgeNumber}@achanger.fr`;
};

const CUSTOMER_PASSWORD_DEFAULT_VALUE = '12345';

type PartialGuestGroupAndPricingRule = Partial<Pick<BaseCustomerAttributes, 'guestGroupId' | 'pricingRuleCode'>>;

const getGuestGroupAndPricingRuleCodeDefaultValues = (
    guestGroups: GuestGroupWithGuestsCount[],
): PartialGuestGroupAndPricingRule => {
    const defaultValues: PartialGuestGroupAndPricingRule = {};

    const brandHasOnlyOneGuestGroup = guestGroups.length === 1;
    if (brandHasOnlyOneGuestGroup) {
        const { guestGroupId, pricingRules } = guestGroups[0];
        defaultValues.guestGroupId = guestGroupId;

        const groupHasOnlyOnePricingRule = pricingRules.length === 1;
        if (groupHasOnlyOnePricingRule) {
            const { code } = pricingRules[0];
            defaultValues.pricingRuleCode = code;
        }
    }
    return defaultValues;
};

export const generateCreateCustomerFormDefaultValuesWithEmailAndPassword = (
    guestGroups: GuestGroupWithGuestsCount[],
): Partial<BaseCustomerAttributes> => {
    const badgeNumber = generateBadgeNumber();
    return {
        badgeNumber,
        badges: [{ value: badgeNumber, type: '', isDefault: true }],
        accountPaymentType: ACCOUNT_PAYMENT_TYPE.PREPAYMENT,
        email: generateCustomerEmailDefaultValue(badgeNumber),
        password: CUSTOMER_PASSWORD_DEFAULT_VALUE,
        confirmPassword: CUSTOMER_PASSWORD_DEFAULT_VALUE,
        ...getGuestGroupAndPricingRuleCodeDefaultValues(guestGroups),
    };
};

export const generateCreateCustomerFormDefaultValues = (
    guestGroups: GuestGroupWithGuestsCount[],
): Partial<BaseCustomerAttributes> => {
    const badgeNumber = generateBadgeNumber();
    return {
        badgeNumber,
        accountPaymentType: ACCOUNT_PAYMENT_TYPE.PREPAYMENT,
        badges: [{ value: badgeNumber, type: '', isDefault: true }],
        ...getGuestGroupAndPricingRuleCodeDefaultValues(guestGroups),
    };
};
