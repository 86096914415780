import React from 'react';
import { useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';
import {
    getAccountPaymentTypeOptions,
    PRICING_CONFIGURATION_LINKS,
} from '@/pages/Customer/PricingConfigurationSection/PricingConfigurationSection.utils';
import { GuestGroupsByDepthKey } from '@/pages/Brand/Groups/types';
import { Select } from '@/components/form/Select';
import { Input } from '@/components/form/Input';
import FieldInfo from '@/components/molecules/Channel/FieldInfo';
import { FlexContainer } from './UpdateInfoForm.style';
import { useUpdateInfoFormVM } from './UpdateInfoForm.viewmodel';

type UpdateCustomerFormProps = {
    brandId: number;
};

const UpdateInfoForm: React.FunctionComponent<React.PropsWithChildren<UpdateCustomerFormProps>> = ({ brandId }) => {
    const intl = useIntl();
    const {
        groups,
        sections,
        subSections,
        isFetchingGroups,
        isFetchingSections,
        isFetchingSubSections,
        disableSection,
        disableSubSection,
        displayInfo,
        handleRedirectionLinkClick,
    } = useUpdateInfoFormVM({ brandId });

    return (
        <FlexContainer>
            <Select
                labelId="customer.student.info.account.type"
                name={'accountPaymentType'}
                rules={{
                    required: computeText(intl, 'field.required'),
                }}
                options={getAccountPaymentTypeOptions(intl)}
            />
            <div>
                <Select
                    labelId="customer.student.info.group"
                    name={'groupId'}
                    rules={{
                        required: computeText(intl, 'field.required'),
                    }}
                    options={groups}
                    isLoading={isFetchingGroups}
                />
                {displayInfo && (
                    <FieldInfo
                        message={computeText(intl, 'customer.form.groupInfo')}
                        handleRedirectionLinkClick={handleRedirectionLinkClick(
                            PRICING_CONFIGURATION_LINKS.GUEST_GROUPS,
                            GuestGroupsByDepthKey.GROUPS,
                        )}
                    />
                )}
            </div>
            <div>
                <Select
                    labelId="customer.student.info.section"
                    name="sectionId"
                    options={sections}
                    isLoading={isFetchingSections}
                    disabled={disableSection}
                />
                {displayInfo && (
                    <FieldInfo
                        message={computeText(intl, 'customer.form.sectionInfo')}
                        handleRedirectionLinkClick={handleRedirectionLinkClick(
                            PRICING_CONFIGURATION_LINKS.GUEST_GROUPS,
                            GuestGroupsByDepthKey.SECTIONS,
                        )}
                    />
                )}
            </div>
            <div>
                <Select
                    labelId="customer.student.info.sub.section"
                    name="subSectionId"
                    options={subSections}
                    isLoading={isFetchingSubSections}
                    disabled={disableSubSection}
                />
                {displayInfo && (
                    <FieldInfo
                        message={computeText(intl, 'customer.form.subSectionInfo')}
                        handleRedirectionLinkClick={handleRedirectionLinkClick(
                            PRICING_CONFIGURATION_LINKS.GUEST_GROUPS,
                            GuestGroupsByDepthKey.SUB_SECTIONS,
                        )}
                    />
                )}
            </div>
            <Input labelId="customer.student.info.pricing.code" name={'tariffCode'} />
            <Input labelId="customer.student.info.student.number" name={'studentNumber'} />
            <Input labelId="customer.student.info.badge.number" name={'badgeNumber'} />
        </FlexContainer>
    );
};

export default UpdateInfoForm;
