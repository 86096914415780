import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { Cell } from 'react-table';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '@/redux/user';
import { getEntranceFee, getEntranceFeeRestaurants, updateEntranceFeeRestaurants } from '@/redux/grantAndEntranceFee';

import { Table } from '@/components/atoms/Table';
import Loader from '@/components/atoms/Loader';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { RestaurantEntranceFeeCreateAttribute } from '@/services/innovorder/entranceFee/entranceFee.type';
import { useGetBrandRestaurantsQuery } from '@/services/innovorder/brand/brand.endpoints';

import { useShift } from '@/hooks/useShift';
import {
    computeEntranceFeeRestaurantTableRowsVM,
    computeEntranceFeeRestaurantTableColumnsVM,
    computeRestaurantsCreateAttributes,
    udpateEnabledRestaurants,
} from './utils';

interface ParamTypes {
    brandId: string;
    entranceFeeId: string;
}

interface EntranceFeeRestaurantsProps {
    isCreateMode?: boolean;
    handleOnConfigure: ({ restaurantId, name }: { restaurantId: number; name: string }) => void;
}

export const EntranceFeeRestaurants: React.FunctionComponent<React.PropsWithChildren<EntranceFeeRestaurantsProps>> = ({
    isCreateMode,
    handleOnConfigure,
}) => {
    const { brandId } = useParams<ParamTypes>();
    const dispatch = useDispatch();

    const token = useSelector(getUserToken);
    const enabledRestaurants = useSelector(getEntranceFeeRestaurants);
    const entranceFee = useSelector(getEntranceFee);
    const { hasShiftRequirement } = useShift();

    const { data } = useGetBrandRestaurantsQuery({
        brandId: parseInt(brandId, 10),
        token,
    });

    const intl = useIntl();
    const theme = useTheme();

    const tableChange = React.useCallback(
        (restaurantId: number, updates: Partial<RestaurantEntranceFeeCreateAttribute>): void => {
            if (data) {
                dispatch(
                    updateEntranceFeeRestaurants(
                        udpateEnabledRestaurants({ updates, enabledRestaurants, allRestaurants: data, restaurantId }),
                    ),
                );
            }
        },
        [data, dispatch, enabledRestaurants],
    );

    useEffect(() => {
        if (isCreateMode && enabledRestaurants.length > 0) {
            dispatch(updateEntranceFeeRestaurants([]));
        }
    }, [isCreateMode, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const computedRestaurants = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return computeRestaurantsCreateAttributes({
            restaurants: data,
            enabledRestaurants,
            entranceFee,
            intl,
            hasShiftRequirement,
        });
    }, [data, enabledRestaurants, entranceFee, intl, hasShiftRequirement]);

    const entranceFeeRestaurantTableRows = React.useMemo(
        () =>
            computeEntranceFeeRestaurantTableRowsVM({
                restaurants: computedRestaurants,
                theme,
                intl,
                isCreateMode,
                onChange: tableChange,
                handleOnConfigure,
            }),
        [computedRestaurants, tableChange, theme, intl, isCreateMode, handleOnConfigure],
    );

    const entranceFeeRestaurantTableColumns = React.useMemo(
        () => computeEntranceFeeRestaurantTableColumnsVM(intl, hasShiftRequirement),
        [intl, hasShiftRequirement],
    );

    const handleOnRowClick = (cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
        if (!hasShiftRequirement) return;
        cell && cell.row.values.editAction.value.onClick();
    };

    if (!data) {
        return <Loader withContainer />;
    }

    return (
        <Table
            rows={entranceFeeRestaurantTableRows}
            columns={entranceFeeRestaurantTableColumns}
            emptyMessageId="grant.emptyMessage"
            isSearchable
            searchPlaceholder="restaurant.search"
            filters={['restaurant']}
            hasPagination
            onRowClick={handleOnRowClick}
        />
    );
};
