import React, { useCallback, useEffect, useMemo } from 'react';
import { Input } from '@/components/atoms/Form/Input';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Text } from '@/components/atoms/Text';
import Select, { Option } from '@/components/atoms/Select';
import { SavingBar } from '@/components/atoms/SavingBar';

import { PostPosAccessUserBody } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.type';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import {
    Container,
    CustomTitle,
    Flex,
    FlexContainer,
    FormChildrens,
    FormContainer,
    MargedTitle,
    TitleContainer,
} from './PosUserForm.style';
import { usePosUserFormVM } from './PosUserForm.viewmodel';

export interface PosUserPanelProps {
    setValid?: (state: boolean) => void;
    setDirty?: (state: boolean) => void;
    error: string;
    loading: boolean;
    onSubmit: (arg: PostPosAccessUserBody) => void;
}

const PosUserForm: React.FunctionComponent<React.PropsWithChildren<PosUserPanelProps>> = ({
    onSubmit,
    error: callError,
    loading,
}: PosUserPanelProps) => {
    const intl = useIntl();
    const { posUser, posRoles, isLoading } = usePosUserFormVM();

    const fieldRequiredErrorText = 'required';

    const PosUserFormResolver = yupResolver(
        Yup.object().shape({
            name: Yup.string().required(fieldRequiredErrorText),
            pinCode: Yup.string()
                .matches(/^.{4}$|^.{8}$/, 'PinCode must be either 4 or 8 characters long')
                .required(fieldRequiredErrorText),

            posRoleId: Yup.number().required(fieldRequiredErrorText),
        }),
    );
    const {
        control,
        handleSubmit,
        reset,
        formState: { dirtyFields },
    } = useForm<PostPosAccessUserBody>({
        resolver: PosUserFormResolver,
        defaultValues: { ...posUser },
    });
    const roleOptions: Option<number>[] = useMemo(
        () =>
            isLoading || !posRoles
                ? []
                : posRoles.map((role) => ({ label: computeText(intl, `posRole.${role.key}`), value: role.posRoleId })),
        [isLoading, posRoles],
    );

    const resetFormData = useCallback((): void => {
        if (posUser) {
            reset(posUser);
        }
    }, [posUser, reset]);

    useEffect(() => {
        resetFormData();
    }, [isLoading, resetFormData]);

    return (
        <Container>
            <FlexContainer>
                <div style={{ flex: 1, paddingRight: 50 }}>
                    <TitleContainer>
                        <CustomTitle text={posUser ? `posUser.edit.form.title` : 'posUser.create.form.title'} />
                        {posUser && <CustomTitle text={posUser.name} />}
                    </TitleContainer>

                    <Text text="posUser.form.description" />
                </div>
                <FormContainer data-testid="posUser-form">
                    <MargedTitle
                        text={posUser ? 'posUser.form.edit' : 'posUser.form.creation'}
                        type="small"
                        weight="medium"
                    />
                    <Flex>
                        <FormChildrens style={{ marginRight: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                                    return (
                                        <Input
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            labelId="posUser.name.form.label"
                                            placeholder="posUser.name.form.placeholder"
                                            error={error?.message}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="pinCode"
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <Input
                                        error={error?.message}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="posUser.pinCode.form.placeholder"
                                        labelId="posUser.pinCode.form.label"
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="posRoleId"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Select<number>
                                        value={value}
                                        options={roleOptions}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        labelId="posUser.role.form.label"
                                    />
                                )}
                            />
                        </FormChildrens>
                    </Flex>
                </FormContainer>
            </FlexContainer>

            <SavingBar
                onSave={handleSubmit(onSubmit)}
                error={callError}
                loading={loading}
                changesCount={Object.keys(dirtyFields).length}
                onCancel={resetFormData}
            />
        </Container>
    );
};
export default PosUserForm;
