const getPosition = (currentVersion: number[][], subVersionToCompare: number[], index: number): number => {
    if (!currentVersion[index] || !currentVersion[index].length) {
        return -1;
    }
    for (let i = 0; i < subVersionToCompare.length; i++) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(currentVersion[index][i]) || subVersionToCompare[i] > currentVersion[index][i]) {
            return -1;
        }
        if (subVersionToCompare[i] < currentVersion[index][i]) {
            return 1;
        }
    }
    return 0;
};

export const isGreaterOrEqual = (currentVersion: string, targetVersion: string): boolean => {
    if (typeof currentVersion !== 'string') {
        return false;
    }

    const parsedCurrentVersion = currentVersion.split('.').map((parts: string) =>
        parts.split('_').map((part: string) => {
            return parseInt(part, 10);
        }),
    );

    const parsedTargetVersion = targetVersion.split('.').map((parts: string) =>
        parts.split('_').map((part: string) => {
            return parseInt(part, 10);
        }),
    );

    for (let i = 0; i < parsedTargetVersion.length; i++) {
        const position = getPosition(parsedCurrentVersion, parsedTargetVersion[i], i);
        if (position === 1) {
            return true;
        }
        if (position === -1) {
            return false;
        }
    }

    return true;
};
