import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { useGetGuestGroupsQuery } from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { useGetActiveStudentsEntitiesQuery } from '@/services/innovorder/brand/brand.endpoints';
import { getUseNewGuestJourneyDesign } from '@/redux/launchDarkly';
import { getUserToken } from '@/redux/user';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';

export type CustomerTableViewModel = {
    onHideCreateModal: () => void;
    handleImport: () => void;
    handleExport: () => void;
    shouldUseNewGuestJourneyDesign: boolean;
    guestGroups?: GuestGroupWithGuestsCount[];
    activeStudentEntities?: ActiveStudentEntities;
};

export const useCustomersListVM = ({ brandId }: { brandId: number }): CustomerTableViewModel => {
    const shouldUseNewGuestJourneyDesign = useSelector(getUseNewGuestJourneyDesign);
    const token = useSelector(getUserToken);

    const { data: guestGroups } = useGetGuestGroupsQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const { data: activeStudentEntities } = useGetActiveStudentsEntitiesQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const onHide = useCallback(() => {
        redirectToBackofficeV1('/home/customers');
    }, []);

    const handleImport = useCallback(() => {
        console.log('handleImport');
    }, []);

    const handleExport = useCallback(() => {
        console.log('handleExport');
    }, []);

    return {
        onHideCreateModal: onHide,
        handleImport,
        handleExport,
        shouldUseNewGuestJourneyDesign,
        guestGroups,
        activeStudentEntities,
    };
};
