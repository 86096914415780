import { PricingRule } from '../guestGroup/guestGroup.type';
import { Paginated } from '../request.types';

export type CreateCustomerPayload = {
    firstName: string;
    lastName: string;
    email: string;
    badgeNumber: string;
    password: string;
    confirmPassword: string;
    departureDate: string;
    arrivalDate: string;
    phoneNumber: string;
    role: string;
    brandId: number;
    accountPaymentType: string;
    accountType: string | null;
    guestGroupId: number;
    pricingRuleCode: string;
    studentNumber?: string;
    section?: string;
    subSection?: string;
    badges?: Array<Partial<Badge>>;
};

export enum AccountType {
    POSTPAYMENT = 'POSTPAYMENT',
    BANKDEBIT = 'BANKDEBIT',
}

export type FullAccountPaymentType = {
    accountPaymentType: string;
    accountType: AccountType | null;
};

export type Customer = {
    customerId: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    badgeNumber: string;
    password: string;
    brandId: number;
    accountType: string;
    guestGroupId: number;
    pricingRules: PricingRule[];
    totalSpent: number;
    totalOrders: number;
    averagePurchase: number;
    lastOrder: Date;
    averageOrderPerWeek: number;
    disabledAt: null | Date;
    archivedAt: null | Date;
    ewalletOverdraftAmount: number;
    balance: number;
    badges: Badge[];
};

export type GetCustomerPayLoad = {
    customerId: number;
};

export type CustomerFetchOptions = {
    getArchivedCustomers: boolean;
    getDisabledCustomers: boolean;
    emailDomainNames: string | null;
    groups: string | null;
    sections: string | null;
    subsections: string | null;
};

export type GetCustomerListPayLoad = Paginated<
    {
        brandId: number;
        selectedRestaurantId?: number;
        search?: string;
        orderBy?: string;
    } & CustomerFetchOptions
>;

export type GetCustomerListResponse = {
    count: number;
    customers: Customer[];
};

export type CustomerAttributes = {
    customerId: number;
    brandId: number;
    phone: string | null;
    passwordExpiresAt: Date | null;
    email: string;
    password: string;
    passwordSalt: string;
    firstName: string | null;
    lastName: string | null;
    badgeNumber: string | null;
    isBadgeBlocked: boolean;
    ewalletOverdraftAmount: number | null;
    created_at: string | null;
    deleted_at: string | null;
    deletedAt: string | null;
    disabledAt: string | null;
    archivedAt: string | null;
    forgottenPasswordTokenDate: Date | null;
    passwordToHash: {
        newPassword: string | null;
    } | null;
    forgottenPasswordToken: string | null;
    tripAdvisorUsername: string | null;
    birthdate: Date | null;
    paymentDailyLimit: number | null;
    lastConnection: Date | null;
    ewalletReloadRecieveConfirmation: boolean | null;
    ewalletReloadConfirmationEmail: string | null;
    accessFrom: Date | null;
    accessUntil: Date | null;
    pinCode: string | null;
    pinCodeSalt: string | null;
    legacyPassword: string | null;
    canOrder?: boolean | undefined;
    lastSeenAt: Date | null;
    totalSpent: number;
    totalOrders: number;
    averagePurchase: number;
    lastOrder: Date;
    averageOrderPerWeek: number;
    accountType: AccountType;
    accountPaymentType: string;
    studentNumber: string;
    class: string;
    section: string;
    subSection: string;
    tariffCode: string;
    grantCode: string;
    entranceFeeCode: string;
    pricingRuleCode: string;
};

export type CustomerForExport = Partial<CustomerAttributes> &
    Partial<{
        balance: string;
        canOrder: boolean;
        optIns: Record<number, { text: string }>;
        relevantEwalletOverdraftAmount?: string | null;
    }>;

export type GetCustomerListForExportResponse = CustomerForExport[];

export type UpdateCustomerType = {
    badgeNumber: string;
    tariffCode: string;
    studentNumber: string;
    accountPaymentType: string;
    guestGroupId: number;
    pricingRuleCode: string;
    section: string;
    subSection: string;
    paymentDailyLimit: number | null;
    overrideDailyPaymentLimit: boolean;
    overrideEwalletOverdraft: boolean;
    ewalletOverdraftAmount: number;
    isBadgeBlocked: boolean;
    entranceFeeCode: string;
    grantCode: string;
};

export type UpdateCustomerV2Type = {
    badgeNumber: string;
    tariffCode: string;
    studentNumber: string;
    accountPaymentType: string;
    groupId: number | null;
    sectionId: number | null;
    subSectionId: number | null;
    pricingRuleId: number | null;
    paymentDailyLimit: number | null;
    overrideDailyPaymentLimit: boolean;
    overrideEwalletOverdraft: boolean;
    ewalletOverdraftAmount: number;
    isBadgeBlocked: boolean;
    entranceFeeCode: string;
    grantCode: string;
};

export interface StudentAttributes {
    studentId: number;
    customerId: number;
    guestGroupId: number | null;
    class: string | null;
    studentNumber: string | null;
    section: string | null;
    subSection: string | null;
    grantCode: string | null;
    entranceFeeCode: string | null;
    tariffCode: string | null;
    barCode: string | null;
    pricingRuleCode: string | null;
    pricingRuleId: number | null;
    accountPaymentType: string;
    accountType: AccountType | null;
    accessFrom: Date | null;
    accessUntil: Date | null;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
}
export interface ICustomerAttributes {
    customerId: number;
    brandId: number;
    phone: string | null;
    passwordExpiresAt: Date | null;
    email: string;
    password: string;
    passwordSalt: string;
    firstName: string | null;
    lastName: string | null;
    badgeNumber: string | null;
    isBadgeBlocked: boolean;
    ewalletOverdraftAmount: number | null;
    overrideEwalletOverdraft: boolean | null;
    created_at: string | null;
    deleted_at: string | null;
    deletedAt: string | null;
    disabledAt: string | null;
    archivedAt: string | null;
    forgottenPasswordTokenDate: number | null;
    passwordToHash: {
        newPassword: string | null;
    } | null;
    forgottenPasswordToken: string | null;
    tripAdvisorUsername: string | null;
    birthdate: Date | null;
    paymentDailyLimit: number | null;
    lastConnection: Date | null;
    ewalletReloadRecieveConfirmation: boolean | null;
    ewalletReloadConfirmationEmail: string | null;
    accessFrom: Date | null;
    accessUntil: Date | null;
    pinCode: string | null;
    pinCodeSalt: string | null;
    legacyPassword: string | null;
    canOrder?: boolean | undefined;
    lastSeenAt: Date | null;
    student?: StudentAttributes;
    badges: Badge[];
}

export type UpdateCustomerPayload = {
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string;
    ewalletReloadRecieveConfirmation?: boolean;
    ewalletReloadConfirmationEmail?: string | null;
    oldPassword?: string | null;
    newPassword?: string | null;
    overrideDailyPaymentLimit?: boolean | null;
    overrideEwalletOverdraft?: boolean | null;
    paymentDailyLimit?: number | null;
    ewalletOverdraftAmount?: number | null;
    badgeNumber?: string | null;
    isBadgeBlocked?: boolean;
    student?: Partial<StudentAttributes> | null;
    tripAdvisorUsername?: string | null;
    birthdate?: Date | null;
    newPinCode?: number | null;
    password?: string;
    automaticEwalletReloadActive?: boolean;
    automaticEwalletReloadMinBalance?: number;
    automaticEwalletReloadAmount?: number;
    automaticEwalletReloadCardId?: number;
    customerId: number;
};

export const ACCOUNT_PAYMENT_TYPE = {
    CASH: 'CASH',
    PREPAYMENT: 'PREPAYMENT',
    POSTPAYMENT_BANKDEBIT: 'POSTPAYMENT_BANKDEBIT',
    POSTPAYMENT_GUEST: 'POSTPAYMENT_GUEST',
};
export const ACCOUNT_TYPE = {
    BANKDEBIT: 'BANKDEBIT',
    POSTPAYMENT: 'POSTPAYMENT',
};

export enum BadgeType {
    CLASSIC = 'classic',
    TOQLA = 'toqla',
}

export type Badge = {
    badgeId: number;
    brandId: number;
    customerId: number;
    value: string;
    type: string;
    isDefault: boolean;
    isBlocked: boolean;
    createdAt: Date;
    updatedAt: Date;
};

export type CreateBadgePayload = Pick<Badge, 'customerId' | 'value' | 'type' | 'isDefault' | 'isBlocked'>;

export type UpdateBadgePayload = Partial<CreateBadgePayload> & Pick<Badge, 'customerId' | 'badgeId'>;

export type DeleteBadgePayload = Pick<Badge, 'customerId' | 'badgeId'>;
