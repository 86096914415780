import { IntlShape } from 'react-intl';
import qs from 'query-string';
import { Option } from '@/components/atoms/Select';
import { computeText } from '@/locales/utils';
import { GuestGroup, GuestGroupWithGuestsCount, PricingRule } from '@/services/innovorder/guestGroup/guestGroup.type';
import { ACCOUNT_PAYMENT_TYPE } from '@/services/innovorder/customer/customer.types';
import { ActiveStudentEntities, StudentEntity } from '@/services/innovorder/brand/brand.type';

export const PRICING_CONFIGURATION_LINKS = {
    GUEST_GROUPS: '/brands/:brandId/groups/create',
    PRICING_RULES: '/brands/:brandId/grants_and_entrance_fees/pricing_rule/create',
};

export const getRedirectionLink = (
    link: string,
    brandId: number,
    params?: Record<string, string | number | undefined>,
): string => {
    const linkWithBrandId = link.replace(':brandId', brandId.toString());
    if (params) {
        const queryString = qs.stringify(params);
        return `${linkWithBrandId}?${queryString}`;
    }
    return linkWithBrandId;
};

export const getAccountPaymentLabel = (intl: IntlShape, type: string): string => {
    return computeText(intl, `customer.form.selectOptions.${type.toLowerCase()}`);
};

export const getPricingRulesDefaultValues = (
    guestGroups: GuestGroupWithGuestsCount[],
    guestGroupId: number | undefined,
): PricingRule[] => {
    let defaultPricingRules: PricingRule[] = [];
    if (guestGroupId) {
        const guestGroup = guestGroups.find(({ guestGroupId: id }) => id === guestGroupId);
        defaultPricingRules = guestGroup ? guestGroup.pricingRules : [];
    }

    return defaultPricingRules;
};

export const getAccountPaymentTypeOptions = (intl: IntlShape): Option<string>[] => {
    return Object.keys(ACCOUNT_PAYMENT_TYPE).map((type) => {
        return {
            label: getAccountPaymentLabel(intl, type),
            value: type,
        };
    });
};

export const getGuestGroupOptions = (groups: GuestGroup[]): Option<number>[] => {
    return groups.map(({ name, guestGroupId }) => {
        return {
            label: name,
            value: guestGroupId,
        };
    });
};

export const getPricingRulesOptions = (pricingRules: PricingRule[]): Option<string>[] => {
    return pricingRules.map(({ code }) => {
        return {
            label: code,
            value: code,
        };
    });
};

export const getSectionsByGuestGroupName = (
    activeStudentEntities?: ActiveStudentEntities,
    guestGroupName?: string,
): StudentEntity[] => {
    const guestGroup = activeStudentEntities?.entities.find((studentEntity) => studentEntity.name === guestGroupName);
    if (!guestGroup) {
        return [];
    }
    return guestGroup?.children.filter(
        (guestGroupSection) => guestGroupSection.name !== null && guestGroupSection.name !== 'null',
    );
};

export const getSubSectionsBySectionName = (sections: StudentEntity[], sectionName?: string): StudentEntity[] => {
    const section = sections?.find((sectionEntity) => sectionEntity.name === sectionName);
    if (!section) {
        return [];
    }
    return section?.children.filter((subSection) => subSection.name !== null && subSection.name !== 'null');
};
