import { Table } from '@/components/atoms/Table';
import React from 'react';
import DeleteModal from '@/components/atoms/DeleteModal';
import { useUsersTableVM } from './UsersList.viewmodel';

const PosUsersListTable: React.FunctionComponent<React.PropsWithChildren> = () => {
    const {
        headers,
        rows,
        isGetUsersLoading,
        isLoadingDelete,
        handleOnRowClick,
        handleOnDeleteClick,
        idToDelete,
        handleCloseModal,
    } = useUsersTableVM();

    return (
        <>
            <DeleteModal
                title="grant.modal.warning"
                description="grant.modal.confirmDelete"
                isOpen={idToDelete !== null}
                onHide={handleCloseModal}
                onSubmit={handleOnDeleteClick}
                isLoading={isLoadingDelete}
            />
            <Table
                isSearchable
                rows={rows}
                emptyMessageId="table.noRowsAfterFilter"
                columns={headers}
                hasPagination
                hasUrlNavigation
                isSortable
                isLoading={isGetUsersLoading}
                onRowClick={handleOnRowClick}
            />
        </>
    );
};

export { PosUsersListTable };
