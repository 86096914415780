import { useCallback, useEffect, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { getEntranceFee, updateEntranceFee } from '@/redux/grantAndEntranceFee';

import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { useGetRestaurantShiftsQuery } from '@/services/innovorder/restaurant/restaurant.endpoints';
import {
    usePostEntranceFeeMutation,
    usePutEntranceFeeMutation,
} from '@/services/innovorder/entranceFee/entranceFee.endpoints';
import { EntranceFeeStrategy, ShiftEntranceFeePayloadData } from '@/services/innovorder/entranceFee/entranceFee.type';
import { Routes } from '@/core/router/routes.types';

import {
    computeEntranceFeeShiftsColumns,
    computeEntranceFeeShiftsRows,
    buildEntranceFee,
    buildEntranceFeeRestaurants,
    areShiftEntranceFeesValid,
} from './utils';
import { EntranceFeeShiftsProps } from './EntranceFeeShifts';

type RestaurantEntranceFeeForm = {
    dailyLimit: number;
    strategy: EntranceFeeStrategy;
    shiftEntranceFees: ShiftEntranceFeePayloadData[];
};

export const useEntranceFeeShiftsVM = ({ restaurantId, name, isCreateMode, handleOnHide }: EntranceFeeShiftsProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const entranceFee = useSelector(getEntranceFee);

    const [rows, setRows] = useState<TableRow[]>([]);
    const columns = computeEntranceFeeShiftsColumns(intl);

    const [postEntranceFee, { isLoading: isPostLoading, isSuccess, data }] = usePostEntranceFeeMutation();
    const [putEntranceFee, { isLoading: isPutLoading }] = usePutEntranceFeeMutation();
    const { data: shifts } = useGetRestaurantShiftsQuery({
        restaurantId: restaurantId.toString(),
    });

    const history = useHistory();

    const restaurantEntranceFee = useMemo(() => {
        return (
            entranceFee?.restaurants?.find((restaurant) => restaurant.restaurantId === restaurantId)
                ?.restaurant_entrance_fee ?? {
                dailyLimit: entranceFee?.dailyLimit ?? null,
                strategy: entranceFee?.strategy ?? EntranceFeeStrategy.Always,
            }
        );
    }, [entranceFee, restaurantId]);

    const shiftEntranceFees = useMemo(() => {
        return (
            entranceFee?.restaurants?.find((restaurant) => restaurant.restaurantId === restaurantId)
                ?.shiftEntranceFees ?? []
        );
    }, [entranceFee, restaurantId]);

    const { control, getValues, setValue, setError } = useForm<RestaurantEntranceFeeForm>({
        defaultValues: {
            strategy: EntranceFeeStrategy.Always,
            dailyLimit: 999,
            shiftEntranceFees: [],
        },
    });

    if (isSuccess && data?.entranceFeeId) {
        history.push(
            generatePath(Routes.EntranceFee, { brandId: entranceFee.brandId, entranceFeeId: data.entranceFeeId }),
        );
    }

    const handleOnRowClick = (cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
        if (!cell) return;
        if (cell.column.id === 'isEnabled') return;
        if (cell.row.values.isEnabled.value.value && (cell.column.id === 'amount' || cell.column.id === 'vat')) return;

        cell.row.values.isEnabled.value.onChange();
    };

    const handleOnChange = useCallback(
        (
            shiftId: string,
            attributes: 'amount' | 'vat' | 'isEnabled',
            value: number | null,
            shift: { name: string; isDefault: boolean },
        ) => {
            if (!shifts || !entranceFee) return;

            const formShiftEntranceFees = getValues('shiftEntranceFees');

            const uploadedShiftEntranceFee = formShiftEntranceFees.find(
                (shiftEntranceFee) => shiftEntranceFee.shiftId === shiftId,
            );

            if (uploadedShiftEntranceFee && attributes === 'isEnabled') {
                setValue(
                    'shiftEntranceFees',
                    formShiftEntranceFees.filter((shiftEntranceFee) => shiftEntranceFee.shiftId !== shiftId),
                );
            }

            if (uploadedShiftEntranceFee && attributes !== 'isEnabled') {
                uploadedShiftEntranceFee[attributes] = value as number;

                setValue(
                    'shiftEntranceFees',
                    formShiftEntranceFees.map((shiftEntranceFee) =>
                        shiftEntranceFee.shiftId === shiftId ? uploadedShiftEntranceFee : shiftEntranceFee,
                    ),
                );
            }

            if (!uploadedShiftEntranceFee && attributes === 'isEnabled') {
                setValue(
                    'shiftEntranceFees',
                    formShiftEntranceFees
                        .map((shiftEntranceFee) => shiftEntranceFee)
                        .concat([
                            {
                                shiftId,
                                shift,
                                amount: entranceFee.amount * CURRENCY_RATE,
                                vat: entranceFee.vat,
                            },
                        ]),
                );
            }

            setRows(
                computeEntranceFeeShiftsRows({
                    shifts,
                    shiftEntranceFees: getValues('shiftEntranceFees'),
                    defaultValues: {
                        amount: entranceFee.amount,
                        vat: entranceFee.vat,
                    },
                    intl,
                    handleOnChange,
                }),
            );
        },
        [entranceFee, getValues, intl, setValue, shifts],
    );

    const handleOnSubmit = async () => {
        if (getValues('dailyLimit') > entranceFee.dailyLimit) {
            setError('dailyLimit', {});
            return;
        }

        if (!areShiftEntranceFeesValid(getValues('shiftEntranceFees'))) {
            return;
        }

        const baseEntranceFeePayload = buildEntranceFee({ entranceFee });
        const updatedRestaurants = buildEntranceFeeRestaurants({
            entranceFee,
            updatedRestaurant: {
                restaurantId,
                restaurantName: name,
                strategy: getValues('strategy'),
                dailyLimit: getValues('dailyLimit'),
            },
            updatedShiftEntranceFees: getValues('shiftEntranceFees'),
        });

        const updatedEntranceFee = {
            ...baseEntranceFeePayload,
            restaurants: updatedRestaurants,
        };

        if (isCreateMode) {
            await postEntranceFee(updatedEntranceFee);
        }

        if (!isCreateMode) {
            await putEntranceFee(updatedEntranceFee);
        }

        dispatch(updateEntranceFee(updatedEntranceFee));
        handleOnHide();
    };

    useEffect(() => {
        if (restaurantEntranceFee && getValues('dailyLimit') !== restaurantEntranceFee.dailyLimit) {
            setValue('dailyLimit', restaurantEntranceFee.dailyLimit);
        }

        if (restaurantEntranceFee && getValues('strategy') !== restaurantEntranceFee.strategy) {
            setValue('strategy', restaurantEntranceFee.strategy);
        }
    }, [restaurantEntranceFee, getValues, setValue]);

    useEffect(() => {
        if (shiftEntranceFees && getValues('shiftEntranceFees')?.length === 0) {
            const initialShiftEntranceFees = shiftEntranceFees.map(({ shiftId, amount, vat, shift }) => {
                return { shiftId, amount, vat, shift };
            });
            setValue('shiftEntranceFees', initialShiftEntranceFees);
        }
    }, [shiftEntranceFees, getValues, setValue]);

    useEffect(() => {
        if (entranceFee && rows.length === 0 && shifts && shiftEntranceFees) {
            setRows(
                computeEntranceFeeShiftsRows({
                    shifts,
                    shiftEntranceFees,
                    defaultValues: {
                        amount: entranceFee.amount,
                        vat: entranceFee.vat,
                    },
                    intl,
                    handleOnChange,
                }),
            );
        }
    }, [entranceFee, intl, rows.length, shiftEntranceFees, shifts, handleOnChange]);

    return {
        control,
        columns,
        rows,
        isLoading: isPostLoading || isPutLoading,
        dailyLimitMax: entranceFee?.dailyLimit ?? 99999,

        handleOnRowClick,
        handleOnChange,
        handleOnSubmit,
    };
};
