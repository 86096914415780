import styled from '@emotion/styled';

export const StyledInfoParagraph = styled.p`
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-left: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.color.grey500};

    & > span {
        color: ${({ theme }) => theme.color.electricBlue};
        :hover {
            cursor: pointer;
        }
    }
`;
