import { useEffect, useMemo } from 'react';

import {
    getGuestGroupOptions,
    getRedirectionLink,
} from '@/pages/Customer/PricingConfigurationSection/PricingConfigurationSection.utils';
import { useSelector } from 'react-redux';
import { getUserRole, getUserToken } from '@/redux/user';
import { Option } from '@/components/atoms/Select';
import { useLazyGetGuestGroupsQuery } from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { useFormContext } from 'react-hook-form';
import { GetGuestGroupsPayload } from '@/services/innovorder/guestGroup/guestGroup.type';
import { ApiRequest } from '@/services/innovorder/request.types';
import { useHistory } from 'react-router';
import { GuestGroupsByDepthKey } from '@/pages/Brand/Groups/types';
import { UserRoles } from '@/services/innovorder/user/user.type';

type OptionsFetchingProps = {
    brandId: number;
    parentFieldId?: number;
    getter: (params: ApiRequest<GetGuestGroupsPayload>) => void;
    params: ApiRequest<GetGuestGroupsPayload>;
};

type FieldClearingProps = {
    field: string;
    isFetching: boolean;
    options: Option<number>[];
};

type UpdateCustomerFormVMProps = {
    brandId: number;
};

type UpdateInfoFormVM = {
    groups: Option<number>[];
    sections: Option<number>[];
    subSections: Option<number>[];
    isFetchingGroups: boolean;
    isFetchingSections: boolean;
    isFetchingSubSections: boolean;
    disableSection: boolean;
    disableSubSection: boolean;
    displayInfo: boolean;
    handleRedirectionLinkClick: (link: string, depth: GuestGroupsByDepthKey) => () => void;
};

const useOptionsFetching = ({ brandId, parentFieldId, getter, params }: OptionsFetchingProps) => {
    useEffect(() => {
        getter(parentFieldId ? { ...params, parentGuestGroupId: parentFieldId } : params);
    }, [brandId, parentFieldId]);
};

const useFieldClearing = ({ field, isFetching, options }: FieldClearingProps) => {
    const { setValue, watch } = useFormContext();
    const watchfield = watch(field);

    useEffect(() => {
        if (!isFetching && field) {
            const exist = options.some((option) => option.value === watchfield);
            if (!exist) {
                setValue(field, null);
            }
        }
    }, [options, isFetching, field]);
};

export const useUpdateInfoFormVM = ({ brandId }: UpdateCustomerFormVMProps): UpdateInfoFormVM => {
    const history = useHistory();
    const { watch } = useFormContext();
    const token = useSelector(getUserToken);
    const userRole = useSelector(getUserRole);
    const isBrand = userRole === UserRoles.brand;

    // Watch the selected group and section
    const watchGroupId = watch('groupId');
    const watchSectionId = watch('sectionId');

    const [getGroups, { isFetching: isFetchingGroups, data: groupsData }] = useLazyGetGuestGroupsQuery();
    const [getSections, { isFetching: isFetchingSections, data: sectionsData }] = useLazyGetGuestGroupsQuery();
    const [getSubSections, { isFetching: isFetchingSubSections, data: subSectionsData }] = useLazyGetGuestGroupsQuery();

    // Memoize guest groups for performance
    const groups = useMemo(() => getGuestGroupOptions(groupsData || []), [groupsData]);
    const sections = useMemo(
        () => getGuestGroupOptions(watchGroupId && sectionsData ? sectionsData : []),
        [sectionsData, watchGroupId],
    );
    const subSections = useMemo(
        () => getGuestGroupOptions(watchSectionId && subSectionsData ? subSectionsData : []),
        [subSectionsData, watchSectionId],
    );

    // Clear fields if they don't exist in the options
    useFieldClearing({ field: 'groupId', isFetching: isFetchingGroups, options: groups });
    useFieldClearing({ field: 'sectionId', isFetching: isFetchingSections, options: sections });
    useFieldClearing({ field: 'subSectionId', isFetching: isFetchingSubSections, options: subSections });

    // Fetch guest groups
    useOptionsFetching({ brandId, getter: getGroups, params: { token, brandId, depth: 0 } });
    useOptionsFetching({
        brandId,
        parentFieldId: watchGroupId,
        getter: getSections,
        params: { token, brandId, depth: 1 },
    });
    useOptionsFetching({
        brandId,
        parentFieldId: watchSectionId,
        getter: getSubSections,
        params: { token, brandId, depth: 2 },
    });

    // Disable section and sub-section if the group is not selected
    const disableSection = useMemo(() => !watchGroupId, [watchGroupId]);
    // Disable sub-section if the section is not selected
    const disableSubSection = useMemo(() => !watchSectionId, [watchSectionId]);

    // Handle redirection link click
    const handleRedirectionLinkClick = (link: string, depth: GuestGroupsByDepthKey) => () => {
        if (brandId) {
            history.push(getRedirectionLink(link, brandId, { depth }));
        }
    };

    return {
        groups,
        sections,
        subSections,
        isFetchingGroups,
        isFetchingSections,
        isFetchingSubSections,
        disableSection,
        disableSubSection,
        handleRedirectionLinkClick,
        displayInfo: isBrand,
    };
};
