import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Brand, BrandType } from '@/services/innovorder/brand/brand.type';
import { RootState } from '@/redux/store';

export const brandSliceName = 'brand';

export type BrandState = Brand | null;

const initialState: BrandState = null;

const brandSlice = createSlice<BrandState, SliceCaseReducers<BrandState>, string>({
    name: brandSliceName,
    initialState,
    reducers: {
        updateBrand: (state, action: PayloadAction<BrandState>) => action.payload,
    },
});

export const getBrand = (state: RootState): BrandState => state.brand;
export const getBrandType = (state: RootState): BrandType | undefined => state.brand?.brandType;
export const getBrandModuleStripeId = (state: RootState): number | null | undefined => state.brand?.moduleStripeId;
export const getBrandLowestPosVersion = (state: RootState): string | undefined => state.brand?.lowestPosVersion;

export const { updateBrand } = brandSlice.actions;
export default brandSlice.reducer;
