import styled from '@emotion/styled';

export const FlexContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.spacing[4]};
    font-family: ${({ theme }): string => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: ${({ theme }) => theme.font.size.small};
`;
