import { ENV } from '@/utils/env';
import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    CancelOrderPayload,
    CancelOrderResponse,
    CreateOrdersReportGenerationRequestPayload,
    GetOrderDetailsPayload,
    GetOrdersPayload,
    GetOrdersResponse,
    OmnichannelOrder,
} from './order.type';
import { TransactionResponse } from '../io-pay/transactions/transaction.type';

const orderApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getOrders: build.mutation<GetOrdersResponse, ApiRequest<GetOrdersPayload>>({
            query: ({ token, brandId, restaurantIds, customerId, order, search, filters, ...otherParams }) => ({
                url: `/v1/orders/`,
                params: {
                    customerId,
                    restaurantIds: !customerId && restaurantIds ? restaurantIds : undefined,
                    brandId: !customerId && !restaurantIds ? brandId : undefined,
                    order: order?.length ? order : undefined,
                    search,
                    ...filters,
                    ...otherParams,
                },
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetOrdersResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getOrderDetails: build.query<OmnichannelOrder, ApiRequest<GetOrderDetailsPayload>>({
            query: ({ orderId, token }) => ({
                url: `/v1/orders/${orderId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<OmnichannelOrder>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getTransactionsByOrder: build.query<TransactionResponse[], ApiRequest<GetOrderDetailsPayload>>({
            query: ({ orderId }) => ({
                url: new URL(`/v1/transactions/order/${orderId}`, ENV.PAYMENT_SERVICE_URL).href,
                method: 'GET',
            }),
            transformResponse: (response: ApiResponse<TransactionResponse[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        refundOrder: build.mutation<CancelOrderResponse, ApiRequest<CancelOrderPayload>>({
            query: ({ token, orderId, cancellationReason, channelId }) => ({
                url: `/orders/${orderId}/refund`,
                body: {
                    cancellationReason,
                    cancellationReasonDescription: null,
                    orderId,
                    source: channelId,
                },
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CancelOrderResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        cancelOrder: build.mutation<CancelOrderResponse, ApiRequest<CancelOrderPayload>>({
            query: ({ token, orderId, cancellationReason, channelId }) => ({
                url: `/orders/${orderId}/cancel`,
                body: {
                    cancellationReason,
                    cancellationReasonDescription: null,
                    orderId,
                    source: channelId,
                },
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CancelOrderResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        createOrdersReportGenerationRequest: build.mutation<
            string,
            ApiRequest<CreateOrdersReportGenerationRequestPayload>
        >({
            query: ({ brandId, token, startDate, endDate, restaurantId, exportFileType, customerId }) => ({
                url: `/reports/orders`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                params: {
                    brand_id: brandId,
                    startDate,
                    endDate,
                    restaurant_id: restaurantId,
                    exportFileTypes: exportFileType.join(','),
                    customer_id: customerId,
                },
            }),
            transformResponse: (response: ApiResponse<string>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetOrdersMutation,
    useGetOrderDetailsQuery,
    useGetTransactionsByOrderQuery,
    useRefundOrderMutation,
    useCancelOrderMutation,
    useCreateOrdersReportGenerationRequestMutation,
} = orderApi;
