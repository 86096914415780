import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import Tabs from '@/components/organisms/Tabs/Tabs';
import Tab from '@/components/organisms/Tabs/Tab';
import { useUrlQueryState } from '@/hooks/routes/useUrlQueryState';
import { computeText } from '@/locales/utils';
import Student from './tabs/Student';
import { type StudentProps } from './tabs/Student';
import { Container } from './Information.style';
import { redirectToCustomerInformationPage } from './Information.utils';
import { useLazyGetGuestGroupsQuery } from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import {
    useLazyGetBrandPaymentConfigurationQuery,
    useLazyGetBrandQuery,
} from '@/services/innovorder/brand/brand.endpoints';
import Loader from '@/components/atoms/Loader';
import { useSelector } from 'react-redux';
import { getUserToken } from '@/redux/user';
import { useGetCustomerByCustomerIdQuery } from '@/services/innovorder/customer/customer.endpoints';
import { getIsInitialized, getUseNewCustomerPage } from '@/redux/launchDarkly';
import Orders, { CustomerOrdersProps } from '@/pages/Customer/Information/tabs/Orders/OrdersList';

type UpdateCustomerParams = {
    customerId: string;
};

enum CustomerTabKeys {
    INFO = 'info',
    EWALLET = 'ewallet',
    ORDERS = 'orders',
    STUDENT = 'student',
}

const CUSTOMER_INFORMATION_TABS = [
    {
        key: CustomerTabKeys.INFO,
        title: 'customer.tab.info',
        component: () => <Fragment />,
        redirectToV1: true,
    },
    {
        key: CustomerTabKeys.EWALLET,
        title: 'customer.tab.ewallet',
        component: () => <Fragment />,
        redirectToV1: true,
    },
    {
        key: CustomerTabKeys.ORDERS,
        title: 'customer.tab.orders',
        component: (props: CustomerOrdersProps) => <Orders {...props} />,
        redirectToV1: false,
    },
    {
        key: CustomerTabKeys.STUDENT,
        title: 'customer.tab.student',
        component: (props: StudentProps) => <Student {...props} />,
        redirectToV1: false,
    },
];

const CustomerInformation: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const { customerId } = useParams<UpdateCustomerParams>();
    const token = useSelector(getUserToken);
    const [query, setQueryParam] = useUrlQueryState();
    const isInitialized = useSelector(getIsInitialized);
    const shouldUseNewCustomerPage = useSelector(getUseNewCustomerPage);
    const selectedTab = useMemo(() => {
        const activeIndex = CUSTOMER_INFORMATION_TABS.findIndex(({ key }) => query.get('tab') === `${key}`);
        return activeIndex < 0 ? 0 : activeIndex;
    }, [CUSTOMER_INFORMATION_TABS, query]);

    const {
        isLoading: isCustomerLoading,
        data: customer,
        refetch,
    } = useGetCustomerByCustomerIdQuery({
        customerId: parseInt(customerId, 10),
        token,
    });
    const [getBrand, { isLoading: isLoadingBrand, data: brand }] = useLazyGetBrandQuery();
    const [getGuestGroups, { isLoading: isLoadingGuestGroups, data: guestGroups }] = useLazyGetGuestGroupsQuery();
    const [
        getBrandPaymentConfiguration,
        { data: brandPaymentConfiguration, isLoading: isLoadingBrandPaymentConfiguration },
    ] = useLazyGetBrandPaymentConfigurationQuery();

    const onRedirectToV1 = useCallback(
        (tab: string) => redirectToCustomerInformationPage(customerId, tab),
        [customerId],
    );

    const onTabChange = (index: number) => {
        CUSTOMER_INFORMATION_TABS[index].redirectToV1 && onRedirectToV1(CUSTOMER_INFORMATION_TABS[index].key);
        !CUSTOMER_INFORMATION_TABS[index].redirectToV1 &&
            setQueryParam('tab', `${CUSTOMER_INFORMATION_TABS[index].key}`);
    };

    useEffect(() => {
        if (customer?.brandId) {
            getGuestGroups({ brandId: customer?.brandId, token });
            getBrandPaymentConfiguration({ brandId: customer?.brandId, token });
            getBrand({ brandId: customer?.brandId, token });
        }
    }, [customer?.brandId]);

    useEffect(() => {
        isInitialized &&
            !shouldUseNewCustomerPage &&
            CUSTOMER_INFORMATION_TABS[selectedTab].key !== CustomerTabKeys.ORDERS &&
            onRedirectToV1(query.get('tab') || CustomerTabKeys.INFO);
    }, [isInitialized, shouldUseNewCustomerPage, onRedirectToV1, selectedTab]);

    if (isCustomerLoading || isLoadingGuestGroups || isLoadingBrandPaymentConfiguration || isLoadingBrand)
        return <Loader withContainer />;

    return (
        <Container>
            {customer && guestGroups && (
                <Tabs onSelectTab={onTabChange} selectedTabIndex={selectedTab}>
                    {CUSTOMER_INFORMATION_TABS.map(({ key, title, component }) => (
                        <Tab
                            key={`tab:${key}`}
                            data-testid={`tab:${key}`}
                            title={computeText(intl, title)}
                            component={component({
                                brand,
                                customer,
                                guestGroups,
                                brandPaymentConfiguration,
                                refetch,
                            })}
                        ></Tab>
                    ))}
                </Tabs>
            )}
        </Container>
    );
};

export default CustomerInformation;
