import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse, ApiResponseBase } from '../request.types';
import {
    PricingRule,
    GetPricingRulesPayload,
    DeletePricingRulesPayload,
    RelatedCustomerCount,
    GetRelatedCustomerPayload,
    PricingRuleCreateAttributes,
    PutRestaurantPricingRuleBody,
    GetPricingRulePayload,
    PricingRules,
    UploadPricingRulesPayload,
    UploadPricingRuleResponse,
} from './pricingRule.type';

const pricingRuleApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getPricingRules: build.query<PricingRules, ApiRequest<GetPricingRulesPayload>>({
            query: ({ brandId, token, ...optionalFilters }) => ({
                url: 'pricing_rules',
                params: { brandId, ...optionalFilters },
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.PricingRule],
            transformResponse: (response: ApiResponse<PricingRules>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getMinimalPricingRules: build.query<PricingRule[], ApiRequest<GetPricingRulesPayload>>({
            query: ({ brandId, token }) => ({
                url: 'pricing_rules/minimal',
                params: { brandId },
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.PricingRule],
            transformResponse: (response: ApiResponse<PricingRule[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getPricingRule: build.query<PricingRule, ApiRequest<GetPricingRulePayload>>({
            query: ({ pricingRuleId, token }) => ({
                url: `/pricing_rules/${pricingRuleId}?include=pricingRules`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.PricingRule],
            transformResponse: (response: ApiResponse<PricingRule & { pricingRules: PricingRule[] }>) => {
                const { pricingRules, ...pricingRuleAttributes } = response.data;
                return { ...pricingRuleAttributes, associatedPricingRules: pricingRules };
            },
            extraOptions: { maxRetries: 3 },
        }),
        postPricingRule: build.mutation<PricingRule, ApiRequest<PricingRuleCreateAttributes>>({
            query: ({
                brandId,
                code,
                applyScope,
                blockedChannels,
                grants,
                entranceFees,
                associatedPricingRules,
                version,
                isUrssafEnabled,
                token,
            }) => ({
                url: `/pricing_rules`,
                method: 'POST',
                body: {
                    brandId,
                    code,
                    applyScope,
                    blockedChannels,
                    grants,
                    entranceFees,
                    pricingRules: associatedPricingRules,
                    version,
                    isUrssafEnabled,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.PricingRule],
            transformResponse: (response: ApiResponse<PricingRule>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putPricingRule: build.mutation<PricingRule, ApiRequest<PutRestaurantPricingRuleBody>>({
            query: ({
                pricingRuleId,
                brandId,
                code,
                applyScope,
                blockedChannels,
                grants,
                entranceFees,
                associatedPricingRules,
                version,
                isUrssafEnabled,
                token,
            }) => ({
                url: `/pricing_rules/${pricingRuleId}`,
                method: 'PUT',
                body: {
                    brandId,
                    code,
                    applyScope,
                    blockedChannels,
                    grants,
                    entranceFees,
                    pricingRules: associatedPricingRules,
                    version,
                    isUrssafEnabled,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.PricingRule],
            transformResponse: (response: ApiResponse<PricingRule>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deletePricingRule: build.mutation<ApiResponseBase, ApiRequest<DeletePricingRulesPayload>>({
            query: ({ pricingRuleId, brandId, token }) => ({
                url: `/pricing_rules/${pricingRuleId}`,
                method: 'DELETE',
                params: { brandId },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.PricingRule],
            extraOptions: { maxRetries: 3 },
        }),
        getRelatedCustomersCount: build.mutation<RelatedCustomerCount, ApiRequest<GetRelatedCustomerPayload>>({
            query: ({ pricingRuleId, token }) => ({
                url: `pricing_rules/${pricingRuleId}/count_related_customers`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<RelatedCustomerCount>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBrandPricingRules: build.mutation<PricingRule[], ApiRequest<GetPricingRulesPayload>>({
            query: ({ brandId, token }) => ({
                url: 'pricing_rules/minimal',
                params: { brandId },
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            // providesTags: [ApiTagTypes.PricingRule],
            transformResponse: (response: ApiResponse<PricingRule[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        uploadBrandPricingRules: build.mutation<UploadPricingRuleResponse, ApiRequest<UploadPricingRulesPayload>>({
            query: ({ brandId, formData, token }) => ({
                url: 'pricing_rules/import',
                params: { brandId },
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            transformResponse: (response: ApiResponse<UploadPricingRuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetMinimalPricingRulesQuery,
    useGetPricingRuleQuery,
    usePostPricingRuleMutation,
    usePutPricingRuleMutation,
    useDeletePricingRuleMutation,
    useGetRelatedCustomersCountMutation,
    useGetBrandPricingRulesMutation,
    useLazyGetPricingRulesQuery,
    useUploadBrandPricingRulesMutation,
    useLazyGetMinimalPricingRulesQuery,
} = pricingRuleApi;
