import { Analytics } from '@/pages/Analytics';
import CreateEntranceFee from '@/pages/Brand/CreateEntranceFee';
import CreateGrant from '@/pages/Brand/CreateGrant';
import CreatePricingRule from '@/pages/Brand/CreatePricingRule';
import EntranceFee from '@/pages/Brand/EntranceFee';
import Grant from '@/pages/Brand/Grant';
import StripeIntegration from '@/pages/Brand/Integrations/StripeIntegration/StripeIntegration';
import { GrantsAndEntranceFees } from '@/pages/Brand/GrantsAndEntranceFees';
import { Options } from '@/pages/Brand/Options';
import { Groups } from '@/pages/Brand/Groups';
import Logout from '@/pages/Logout';
import PricingRule from '@/pages/Brand/PricingRule';
import DeliverectConfig from '@/pages/Config/Deliverect/DeliverectConfig';
import UbereatsConfig from '@/pages/Config/Ubereats';
import DeliverooConfig from '@/pages/Config/Deliveroo/DeliverooConfig';
import IOPayConfig from '@/pages/Config/IOPay';
import NotFound from '@/pages/NotFound';
import PermissionDenied from '@/pages/PermissionDenied';
import Orders from '@/pages/Order/OrderList';
import OrderDetails from '@/pages/Order/OrderDetails';
import { PricingRevaluation } from '@/pages/Restaurant/PricingRevaluation';
import { PaymentMethods } from '@/pages/Restaurant/PaymentMethods';
import ExternalPosConfig from '@/pages/Config/ExternalPos';
import ListCustomer from '@/pages/Customer/List/List';
import CustomerSettings from '@/pages/Customer/Settings/Settings';
import CustomerPricingRules from '@/pages/Customer/PricingRules/PricingRules';
import { AccessFlags } from '@/services/innovorder/auth/auth.type';
import CreateGroup from '@/pages/Brand/CreateGroup';
import UpdateGroup from '@/pages/Brand/UpdateGroup';
import AutoBilling from '@/pages/Brand/AutoBilling';
import Transactions from '@/pages/IO-Pay/Transactions/Transactions';
import Reports from '@/pages/IO-Pay/Reports/Reports';
import CreateBillingEntity from '@/pages/Brand/AutoBilling/AutoBillingReferential/BillingEntities/CreateBillingEntity';
import AutoBillingReferential from '@/pages/Brand/AutoBilling/AutoBillingReferential';
import EditBillingEntity from '@/pages/Brand/AutoBilling/AutoBillingReferential/BillingEntities/EditBillingEntity';
import MenuList from '@/pages/MenuList';
import MenuOperationList from '@/pages/MenuOperationList';
import UpdateCustomer from '@/pages/Customer/UpdateCustomer';
import CustomerInformation from '@/pages/Customer/Information';
import Transfers from '@/pages/IO-Pay/Transfers/Transfers';
import TransferDetails from '@/pages/IO-Pay/Transfers/TransferDetails/TransferDetails';
import ReportGenerationsHistory from '@/pages/Reporting/ReportGenerationsHistory';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { BrandOptionName } from '@/services/innovorder/brand/brand.type';
import TransactionDetail from '@/pages/IO-Pay/Transactions/TransactionDetails/TransactionDetail';
import EdenredConfig from '@/pages/Config/Edenred/EdenredConfig';
import PaygreenConfig from '@/pages/Config/Paygreen/PaygreenConfig';
import BrandAdminTools from '@/pages/Brand/AdminTools';
import BadgeReader from '@/pages/Brand/BadgeReader';
import Report from '@/pages/Reporting/Report';
import CreateWebhook from '@/pages/Config/Webhooks/CreateWebhook';
import EditWebhook from '@/pages/Config/Webhooks/EditWebhook';
import { RestaurantMenu } from '@/pages/Restaurant/RestaurantMenu';
import { BrandMenu } from '@/pages/Brand/BrandMenu';
import BrandHistory from '@/pages/BrandHistory/BrandHistory';
import Customer from '@/pages/Brand/Customer';
import { Configuration } from '@/pages/IO-Pay/Configuration/Configuration';
import { Billing } from '@/pages/Billing/Billing';
import CrossSelling from '@/pages/Brand/CrossSelling';
import SalesSummary from '@/pages/Reporting/SalesSummary';
import { RestaurantOptionsTab } from '@/pages/Restaurant/RestaurantOptionsTab';
import { PosUsersList } from '@/pages/Restaurant/PosAccess/PosUsers/PosUsersList/UsersList';
import { CreatePosAccessUser } from '@/pages/Restaurant/PosAccess/PosUsers/CreatePosUser/CreatePosUser';
import { EditPosAccessUser } from '@/pages/Restaurant/PosAccess/PosUsers/EditPosUser/EditPosUser';
import { PosRolesList } from '@/pages/Restaurant/PosAccess/PosRoles/PosRolesList/RolesList';
import { PosGroupsList } from '@/pages/Restaurant/PosAccess/PosGroups/PosGroupsList/GroupsList';
import { CreatePosAccessGroup } from '@/pages/Restaurant/PosAccess/PosGroups/CreatePosGroup/CreatePosGroup';
import { EditPosAccessGroup } from '@/pages/Restaurant/PosAccess/PosGroups/EditPosGroup/EditPosGroup';
import { EditPosAccessRole } from '@/pages/Restaurant/PosAccess/PosRoles/EditPosRole/EditPosRole';
import ChannelList from '@/pages/ChannelList/ChannelList';
import AccessFlagsControl from '@/pages/AccessFlagsControl';
import BrandsList from '@/pages/BrandsList';
import BrandsUsersList from '@/pages/BrandUsersList/BrandsUsersList';
import TableLayoutList from '@/pages/Restaurant/TableLayout/List/TableLayoutList';
import EditTableLayout from '@/pages/Restaurant/TableLayout/RoomList/RoomList';
import CrousConfig from '@/pages/Config/Crous';
import DeviceInformation from '@/pages/Pos/CatalogHistory';
import { NavigationParametersEnum, RedirectionDeclaration, RouteDeclaration, Routes } from './routes.types';

export const redirections: RedirectionDeclaration[] = [
    {
        from: Routes.Home,
        to: Routes.Analytics,
        exact: true,
    },
    {
        from: Routes.IOPay,
        to: Routes.IOPayTransactions,
        exact: true,
    },
];

export const _routes: RouteDeclaration[] = [
    {
        name: 'route.logout',
        path: Routes.Logout,
        component: Logout,
    },
    {
        name: 'route.createGrant',
        path: Routes.CreateGrant,
        component: CreateGrant,
        parent: Routes.GrantsAndEntranceFees,
        context: [NavigationParametersEnum.brandId],
        accessFlags: [AccessFlags.CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION],
    },
    {
        name: 'route.grant',
        path: Routes.Grant,
        component: Grant,
        parent: Routes.GrantsAndEntranceFees,
        context: [NavigationParametersEnum.brandId],
        accessFlags: [AccessFlags.CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION],
    },
    {
        name: 'route.createEntranceFee',
        path: Routes.CreateEntranceFee,
        component: CreateEntranceFee,
        parent: Routes.GrantsAndEntranceFees,
        context: [NavigationParametersEnum.brandId],
        accessFlags: [AccessFlags.CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION],
    },
    {
        name: 'route.entranceFee',
        path: Routes.EntranceFee,
        component: EntranceFee,
        parent: Routes.GrantsAndEntranceFees,
        context: [NavigationParametersEnum.brandId],
        accessFlags: [AccessFlags.CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION],
    },
    {
        name: 'route.listCustomers',
        path: Routes.ListCustomers,
        tab: 'customers',
        component: ListCustomer,
        parent: Routes.Home,
        tabIndex: 0,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.customerSettings',
        path: Routes.CustomerSettings,
        tab: 'customers',
        component: CustomerSettings,
        parent: Routes.Home,
        tabIndex: 1,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.customerPricingRules',
        path: Routes.CustomerPricingRules,
        tab: 'customers',
        component: CustomerPricingRules,
        parent: Routes.Home,
        tabIndex: 2,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.updateCustomer',
        path: Routes.UpdateCustomer,
        component: UpdateCustomer,
        context: [NavigationParametersEnum.customerId],
        backToV1: ':backofficeUrl/home/customers/:customerId/info',
    },
    {
        name: 'route.CustomerInformation',
        path: Routes.CustomerInfo,
        component: CustomerInformation,
        context: [NavigationParametersEnum.customerId],
        backToV1: Routes.CustomerInfoV1,
    },
    {
        name: 'route.createPricingRule',
        path: Routes.CreatePricingRule,
        component: CreatePricingRule,
        parent: Routes.GrantsAndEntranceFees,
        context: [NavigationParametersEnum.brandId],
        accessFlags: [AccessFlags.CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION],
    },
    {
        name: 'route.pricingRule',
        path: Routes.PricingRule,
        component: PricingRule,
        parent: Routes.GrantsAndEntranceFees,
        context: [NavigationParametersEnum.brandId],
        accessFlags: [AccessFlags.CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION],
    },
    {
        name: 'route.brandInfo',
        uri: Routes.BrandInfo,
        tab: 'brands',
        tabIndex: 0,
        parent: Routes.Brands,
        roles: [UserRoles.admin],
        context: [NavigationParametersEnum.brandId],
        breadCrumb: {
            source: NavigationParametersEnum.brandId,
        },
    },
    {
        name: 'route.brandChannels',
        uri: Routes.BrandChannels,
        tab: 'brands',
        tabIndex: 1,
        disableFeatureFlag: 'useNewChannelsPage',
    },
    {
        name: 'route.brandChannels',
        path: Routes.BrandChannelsV2,
        component: ChannelList,
        context: [NavigationParametersEnum.brandId],
        parent: Routes.Home,
        tab: 'brands',
        tabIndex: 1,
        backToV1: Routes.BrandChannels,
        enableFeatureFlag: 'useNewChannelsPage',
    },
    {
        name: 'route.brandMenus',
        path: Routes.BrandMenus,
        component: MenuList,
        context: [NavigationParametersEnum.brandId],
        parent: Routes.Home,
        tab: 'brands',
        tabIndex: 2,
    },
    {
        name: 'route.brandRestaurants',
        uri: Routes.BrandRestaurants,
        tab: 'brands',
        tabIndex: 3,
    },
    {
        name: 'route.brandUsers',
        uri: Routes.BrandUsers,
        tab: 'brands',
        roles: [UserRoles.admin],
        tabIndex: 4,
        disableFeatureFlag: 'useNewUsersPage',
    },
    {
        name: 'route.brandUsers',
        path: Routes.BrandUsersV2,
        component: BrandsUsersList,
        parent: Routes.Home,
        roles: [UserRoles.admin, UserRoles.brand],
        tab: 'brands',
        tabIndex: 4,
        context: [NavigationParametersEnum.brandId],
        enableFeatureFlag: 'useNewUsersPage',
    },
    {
        name: 'route.brandCustomer',
        path: Routes.BrandCustomer,
        component: Customer,
        parent: Routes.Home,
        roles: [UserRoles.admin],
        tab: 'brands',
        tabIndex: 5,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.tickets',
        uri: Routes.BrandTickets,
        tab: 'brands',
        tabIndex: 6,
    },
    {
        name: 'route.brandMedias',
        uri: Routes.BrandMedias,
        tab: 'brands',
        tabIndex: 7,
    },
    {
        name: 'route.crossSelling',
        roles: [UserRoles.admin, UserRoles.brand],
        path: Routes.BrandCrossSelling,
        component: CrossSelling,
        context: [NavigationParametersEnum.brandId],
        parent: Routes.BrandInfo,
        tab: 'brands',
        tabIndex: 6,
        brandOption: BrandOptionName.FOOD_COURT_MODE,
    },
    {
        name: 'route.stripeIntegration',
        path: Routes.StripeIntegration,
        component: StripeIntegration,
        parent: Routes.Integrations,
        context: [NavigationParametersEnum.brandId],
        roles: [UserRoles.admin],
        brandOption: BrandOptionName.FOOD_COURT_MODE,
    },
    {
        name: 'route.grantsAndEntranceFees',
        path: Routes.GrantsAndEntranceFees,
        component: GrantsAndEntranceFees,
        context: [NavigationParametersEnum.brandId],
        tab: 'brands',
        tabIndex: 8,
        parent: Routes.Home,
        accessFlags: [AccessFlags.CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION],
    },
    {
        name: 'route.options',
        path: Routes.Options,
        component: Options,
        roles: [UserRoles.admin],
        context: [NavigationParametersEnum.brandId],
        tab: 'brands',
        tabIndex: 9,
        parent: Routes.Home,
        backToV1: ':backofficeUrl/home/brands/:brandId/options',
    },
    {
        name: 'route.reportStatus',
        uri: Routes.BrandReportStatus,
        tab: 'brands',
        tabIndex: 10,
    },
    {
        name: 'route.groups',
        path: Routes.Groups,
        component: Groups,
        context: [NavigationParametersEnum.brandId],
        tab: 'brands',
        tabIndex: 8,
        parent: Routes.Home,
        enableFeatureFlag: 'useNewGuestJourneyDesign',
    },
    {
        name: 'route.adminTools',
        path: Routes.BrandAdminTools,
        component: BrandAdminTools,
        context: [NavigationParametersEnum.brandId],
        roles: [UserRoles.admin],
        tab: 'brands',
        tabIndex: 11,
        parent: Routes.Home,
    },
    {
        name: 'route.brandHistory',
        path: Routes.BrandHistory,
        component: BrandHistory,
        context: [NavigationParametersEnum.brandId],
        tab: 'brands',
        roles: [UserRoles.admin],
        tabIndex: 12,
        parent: Routes.Home,
    },
    {
        name: 'route.badgeReader',
        path: Routes.BadgeReader,
        component: BadgeReader,
        context: [NavigationParametersEnum.brandId],
        tab: 'brands',
        roles: [UserRoles.admin],
        tabIndex: 15,
        parent: Routes.Home,
    },
    {
        name: 'route.createGroup',
        path: Routes.CreateGroup,
        component: CreateGroup,
        context: [NavigationParametersEnum.brandId],
        parent: Routes.Groups,
        enableFeatureFlag: 'useNewGuestJourneyDesign',
    },
    {
        name: 'route.editGroup',
        path: Routes.EditGroup,
        component: UpdateGroup,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.groupId],
        parent: Routes.Groups,
        enableFeatureFlag: 'useNewGuestJourneyDesign',
    },
    {
        name: 'route.autoBilling',
        path: Routes.AutoBilling,
        component: AutoBilling,
        context: [NavigationParametersEnum.brandId],
        parent: Routes.Home,
    },
    {
        name: 'route.autoBilling.referential',
        path: Routes.AutoBillingReferential,
        component: AutoBillingReferential,
        parent: Routes.AutoBilling,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.autoBilling.referential.createEntity',
        path: Routes.CreateBillingEntity,
        component: CreateBillingEntity,
        parent: Routes.AutoBillingReferential,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.autoBilling.referential.editEntity',
        path: Routes.EditBillingEntity,
        component: EditBillingEntity,
        parent: Routes.AutoBillingReferential,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.billingEntityCode],
    },
    {
        name: 'route.analytics',
        path: Routes.Analytics,
        component: Analytics,
    },
    {
        name: 'route.permissionDenied',
        path: Routes.PermissionDenied,
        component: PermissionDenied,
    },
    {
        name: 'route.notFound',
        path: Routes.NotFound,
        component: NotFound,
    },
    {
        name: 'route.orders',
        path: Routes.Orders,
        component: Orders,
        backToV1: ':backofficeUrl/home/orders',
    },
    {
        name: 'route.orderDetails',
        path: Routes.OrderDetails,
        component: OrderDetails,
        parent: Routes.Orders,
        context: [NavigationParametersEnum.orderId],
        backToV1: ':backofficeUrl/home/orders',
    },
    {
        name: 'route.ReportGenerationsHistory',
        path: Routes.ReportGenerationsHistory,
        component: ReportGenerationsHistory,
    },
    {
        name: 'route.report',
        path: Routes.Report,
        component: Report,
    },
    {
        name: 'route.ioPayTransactions',
        path: Routes.IOPayTransactions,
        component: Transactions,
        accessFlags: [AccessFlags.CAN_ACCESS_IO_PAY],
        parent: Routes.Home,
        tab: 'iopay',
        tabIndex: 0,
    },
    {
        name: 'route.ioPayTransactionDetail',
        path: Routes.IOPayTransactionDetail,
        component: TransactionDetail,
        accessFlags: [AccessFlags.CAN_ACCESS_IO_PAY],
        parent: Routes.IOPayTransactions,
    },
    {
        name: 'route.ioPayTransfers',
        path: Routes.IOPayTransfers,
        component: Transfers,
        accessFlags: [AccessFlags.CAN_ACCESS_IO_PAY],
        parent: Routes.Home,
        tab: 'iopay',
        tabIndex: 1,
    },
    {
        name: 'route.ioPayTransferDetail',
        path: Routes.IOPayTransferDetail,
        component: TransferDetails,
        accessFlags: [AccessFlags.CAN_ACCESS_IO_PAY],
        parent: Routes.IOPayTransfers,
    },
    {
        name: 'route.ioPayReports',
        path: Routes.IOPayReports,
        component: Reports,
        accessFlags: [AccessFlags.CAN_ACCESS_IO_PAY],
        parent: Routes.Home,
        tab: 'iopay',
        tabIndex: 2,
    },
    {
        name: 'route.ioPayConfiguration',
        path: Routes.IOPayConfiguration,
        component: Configuration,
        accessFlags: [AccessFlags.CAN_ACCESS_IO_PAY],
        parent: Routes.Home,
        tab: 'iopay',
        tabIndex: 3,
        enableFeatureFlag: 'useIopayKycInterface',
    },
    {
        name: 'route.billing',
        path: Routes.Billing,
        component: Billing,
        parent: Routes.Home,
        enableFeatureFlag: 'useBillingSpace',
    },
    {
        name: 'route.configUbereats',
        path: Routes.ConfigUbereats,
        component: UbereatsConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
    },
    {
        name: 'route.configCrous',
        path: Routes.ConfigCrous,
        component: CrousConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
    },
    {
        name: 'route.configDeliverect',
        path: Routes.ConfigDeliverect,
        component: DeliverectConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
    },
    {
        name: 'route.configDeliveroo',
        path: Routes.ConfigDeliveroo,
        component: DeliverooConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
    },
    {
        name: 'route.configExternalPos',
        path: Routes.ConfigExternalPos,
        component: ExternalPosConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
    },
    {
        name: 'route.configIOPay',
        path: Routes.ConfigIOPay,
        component: IOPayConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.configEdenred',
        path: Routes.ConfigEdenred,
        component: EdenredConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId],
    },
    {
        name: 'route.configPaygreen',
        path: Routes.ConfigPaygreen,
        component: PaygreenConfig,
        parent: Routes.RestaurantInfos,
        context: [NavigationParametersEnum.brandId],
        backToV1: ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/integrations/e-luncheon-vouchers',
    },
    {
        name: 'route.createWebhook',
        path: Routes.CreateWebhook,
        component: CreateWebhook,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
    },
    {
        name: 'route.editWebhook',
        path: Routes.EditWebhook,
        component: EditWebhook,
        context: [
            NavigationParametersEnum.brandId,
            NavigationParametersEnum.restaurantId,
            NavigationParametersEnum.webhookId,
        ],
    },
    {
        name: 'route.restaurantInfo',
        uri: Routes.RestaurantInfos,
        parent: Routes.BrandRestaurants,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        breadCrumb: {
            source: NavigationParametersEnum.restaurantId,
        },
        roles: [UserRoles.admin, UserRoles.brand],
        tab: 'restaurants',
        tabIndex: 0,
    },
    {
        name: 'route.restaurantInfo',
        uri: Routes.RestaurantUserInfos,
        parent: Routes.BrandInfo,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        breadCrumb: {
            source: NavigationParametersEnum.restaurantId,
        },
        roles: [UserRoles.restaurant],
        tab: 'restaurants',
        tabIndex: 0,
    },
    {
        name: 'route.brands',
        uri: Routes.Brands,
        roles: [UserRoles.admin],
    },
    {
        name: 'route.brandsV2',
        path: Routes.BrandsV2,
        component: BrandsList,
        roles: [UserRoles.admin],
        enableFeatureFlag: 'useNewBrandsPage',
    },
    {
        name: 'route.restaurantChannels',
        uri: Routes.RestaurantChannels,
        tab: 'restaurants',
        tabIndex: 1,
        roles: [UserRoles.admin, UserRoles.brand],
        disableFeatureFlag: 'useNewChannelsPage',
    },
    {
        name: 'route.restaurantChannels',
        uri: Routes.RestaurantUserChannels,
        tab: 'restaurants',
        tabIndex: 2,
        roles: [UserRoles.restaurant],
        disableFeatureFlag: 'useNewChannelsPage',
    },
    {
        name: 'route.restaurantChannels',
        path: Routes.RestaurantChannelsV2,
        component: ChannelList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'restaurants',
        roles: [UserRoles.admin, UserRoles.brand],
        tabIndex: 1,
        backToV1: Routes.RestaurantChannels,
        enableFeatureFlag: 'useNewChannelsPage',
    },
    {
        name: 'route.restaurantChannels',
        path: Routes.RestaurantChannelsV2,
        component: ChannelList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'restaurants',
        roles: [UserRoles.restaurant],
        tabIndex: 2,
        backToV1: Routes.RestaurantUserChannels,
        enableFeatureFlag: 'useNewChannelsPage',
    },
    {
        name: 'route.restaurantUsers',
        uri: Routes.RestaurantUsers,
        tab: 'restaurants',
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand],
    },
    {
        name: 'route.menus',
        path: Routes.RestaurantMenus,
        component: MenuList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'restaurants',
        tabIndex: 4,
    },
    {
        name: 'route.menuOperations',
        path: Routes.MenuOperations,
        component: MenuOperationList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.Menus,
    },
    {
        name: 'route.restaurantTickets',
        uri: Routes.RestaurantTickets,
        tab: 'restaurants',
        tabIndex: 7,
        roles: [UserRoles.admin],
    },
    {
        name: 'route.restaurantMedias',
        uri: Routes.RestaurantMedias,
        roles: [UserRoles.admin, UserRoles.brand],
        tab: 'restaurants',
        tabIndex: 8,
    },
    {
        name: 'route.restaurantMedias',
        uri: Routes.RestaurantUserMedias,
        roles: [UserRoles.restaurant],
        tab: 'restaurants',
        tabIndex: 9,
    },
    {
        name: 'route.restaurantIntegrations',
        uri: Routes.RestaurantIntegrations,
        tab: 'restaurants',
        roles: [UserRoles.admin],
        tabIndex: 10,
    },
    {
        name: 'route.restaurantPayments',
        path: Routes.RestaurantPayments,
        component: PaymentMethods,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'restaurants',
        tabIndex: 11,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
        backToV1: ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/payment',
    },
    {
        name: 'route.restaurantOptions',
        path: Routes.RestaurantOptions,
        component: RestaurantOptionsTab,
        roles: [UserRoles.admin],
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        tab: 'restaurants',
        tabIndex: 12,
        parent: Routes.RestaurantInfos,
    },
    {
        name: 'route.restaurantPricingRevaluation',
        path: Routes.RestaurantPricingRevaluation,
        component: PricingRevaluation,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.Home,
        tabIndex: 13,
        tab: 'restaurants',
        enableFeatureFlag: 'useOpsBulkTools',
        roles: [UserRoles.admin],
    },
    {
        name: 'route.restaurantMenu',
        path: Routes.RestaurantMenu,
        component: RestaurantMenu,
        context: [
            NavigationParametersEnum.brandId,
            NavigationParametersEnum.restaurantId,
            NavigationParametersEnum.menuId,
        ],
        parent: Routes.RestaurantMenus,
        roles: [UserRoles.admin, UserRoles.restaurant, UserRoles.brand],
    },
    {
        name: 'route.brandMenu',
        path: Routes.BrandMenu,
        component: BrandMenu,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.menuId],
        parent: Routes.BrandMenus,
        roles: [UserRoles.admin, UserRoles.brand],
    },
    {
        name: 'route.salesSummary',
        path: Routes.SalesSummary,
        parent: Routes.Home,
        component: SalesSummary,
        roles: [UserRoles.brand],
    },
    {
        name: 'route.posConfiguration',
        uri: Routes.PosConfiguration,
        tab: 'pos',
        roles: [UserRoles.admin],
        tabIndex: 1,
    },
    {
        name: 'route.posDevices',
        uri: Routes.PosDevices,
        tab: 'pos',
        roles: [UserRoles.admin],
        tabIndex: 2,
    },
    {
        name: 'route.posAccess',
        path: Routes.PosAccess,
        component: PosUsersList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'pos',
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.users',
        path: Routes.PosAccessUsers,
        component: PosUsersList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'posAccess',
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.users',
        path: Routes.CreatePosAccessUser,
        component: CreatePosAccessUser,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.users',
        path: Routes.EditPosAccessUser,
        component: EditPosAccessUser,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.roles',
        path: Routes.PosAccessRoles,
        component: PosRolesList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'posAccess',
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.roles',
        path: Routes.EditPosAccessRole,
        component: EditPosAccessRole,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.groups',
        path: Routes.PosAccessGroups,
        component: PosGroupsList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'posAccess',
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.groups',
        path: Routes.CreatePosAccessGroup,
        component: CreatePosAccessGroup,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.posAccess.groups',
        path: Routes.EditPosAccessGroup,
        component: EditPosAccessGroup,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tabIndex: 3,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.tableLayout',
        path: Routes.RestaurantTableLayouts,
        component: TableLayoutList,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantInfos,
        tab: 'pos',
        tabIndex: 4,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.tableLayout.room',
        path: Routes.RestaurantTableLayoutsRooms,
        component: EditTableLayout,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.restaurantId],
        parent: Routes.RestaurantTableLayouts,
        roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
    },
    {
        name: 'route.accessFlags',
        path: Routes.AccessFlags,
        component: AccessFlagsControl,
        context: [NavigationParametersEnum.brandId, NavigationParametersEnum.userId],
        tab: 'user',
        tabIndex: 2,
        parent: Routes.BrandInfo,
        roles: [UserRoles.admin],
        disableFeatureFlag: 'useNewBrandUserPermissionPage',
    },

    // V1 routes
    {
        name: 'route.categories',
        uri: Routes.BrandMenuCategoriesV1,
        tab: 'brandMenus',
        tabIndex: 0,
    },
    {
        name: 'route.products',
        uri: Routes.BrandMenuProductsV1,
        tab: 'brandMenus',
        tabIndex: 1,
    },
    {
        name: 'route.steps',
        uri: Routes.BrandMenuStepsV1,
        tab: 'brandMenus',
        tabIndex: 2,
    },
    {
        name: 'route.tags',
        uri: Routes.BrandMenuTagsV1,
        tab: 'brandMenus',
        tabIndex: 3,
    },

    {
        name: 'route.updateUserPassword',
        uri: Routes.UpdateUserPassword,
        tab: 'user',
        tabIndex: 1,
        disableFeatureFlag: 'useNewBrandUserPermissionPage',
    },
    {
        name: 'route.userInfo',
        uri: Routes.BrandUserInfo,
        tab: 'user',
        tabIndex: 0,
        disableFeatureFlag: 'useNewBrandUserPermissionPage',
    },
    {
        name: 'route.posDeviceInformation',
        uri: Routes.PosDeviceInformationV1,
        parent: Routes.PosDevices,
        context: [NavigationParametersEnum.posDeviceId],
        tab: 'posDevice',
        tabIndex: 1,
    },
    {
        name: 'route.posDeviceConfiguration',
        uri: Routes.PosDeviceConfigurationV1,
        parent: Routes.PosDevices,
        context: [NavigationParametersEnum.posDeviceId],
        tab: 'posDevice',
        tabIndex: 2,
    },
    {
        name: 'route.posDeviceAdditionalDevices',
        uri: Routes.PosDeviceAdditionalDevicesV1,
        parent: Routes.PosDevices,
        context: [NavigationParametersEnum.posDeviceId],
        tab: 'posDevice',
        tabIndex: 3,
    },
    {
        name: 'route.posDeviceArchives',
        uri: Routes.PosDeviceArchivesV1,
        parent: Routes.PosDevices,
        context: [NavigationParametersEnum.posDeviceId],
        tab: 'posDevice',
        tabIndex: 4,
    },
    {
        name: 'route.posDeviceFeatures',
        uri: Routes.PosDeviceFeaturesV1,
        parent: Routes.PosDevices,
        context: [NavigationParametersEnum.posDeviceId],
        tab: 'posDevice',
        tabIndex: 5,
    },
    {
        name: 'route.posDevicePreferences',
        uri: Routes.PosDevicePreferencesV1,
        parent: Routes.PosDevices,
        context: [NavigationParametersEnum.posDeviceId],
        tab: 'posDevice',
        tabIndex: 6,
    },
    {
        name: 'route.posDeviceCatalogHistory',
        path: Routes.PosDeviceCatalogHistory,
        component: DeviceInformation,
        parent: Routes.PosDevices,
        context: [NavigationParametersEnum.posDeviceId],
        tab: 'posDevice',
        tabIndex: 7,
    },
];

// Needed to avoid matching parent route before child route
export const orderRoutesByDepth = (r: RouteDeclaration[]): RouteDeclaration[] =>
    r.sort((a, b) => (b.path?.match(/\//g)?.length ?? 0) - (a.path?.match(/\//g)?.length ?? 0));

export const routes: RouteDeclaration[] = orderRoutesByDepth(_routes);
