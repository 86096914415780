import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { stringifyError } from '@/utils/errors';
import Loader from '@/components/atoms/Loader';
import EntranceFeeForm from '@/pages/Brand/EntranceFee/EntranceFeeForm';
import {
    useGetEntranceFeeQuery,
    usePutEntranceFeeMutation,
} from '@/services/innovorder/entranceFee/entranceFee.endpoints';
import {
    updateEntranceFee,
    updateEntranceFeeRestaurants,
    getEntranceFee,
    getEntranceFeeRestaurants,
} from '@/redux/grantAndEntranceFee';
import { getUserToken } from '@/redux/user';
import type { EntranceFee as EntranceFeeType } from '@/services/innovorder/entranceFee/entranceFee.type';

import { useShift } from '@/hooks/useShift/useShift';
import {
    computeEntranceFeeForQuery,
    amountToEuro,
    computeRestaurantCustomisations,
    formatRestaurantsForQuery,
} from './EntranceFeeForm/EntranceFeeRestaurants/utils';

interface ParamTypes {
    brandId: string;
    entranceFeeId: string;
}

const EntranceFee: React.FunctionComponent<React.PropsWithChildren> = () => {
    const theme = useTheme();

    const { entranceFeeId, brandId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const entranceFee = useSelector(getEntranceFee);
    const entranceFeeRestaurants = useSelector(getEntranceFeeRestaurants);
    const dispatch = useDispatch();
    const { data, error } = useGetEntranceFeeQuery(
        {
            entranceFeeId: parseInt(entranceFeeId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );
    const [putEntranceFee, { isSuccess: isPutSuccess, error: putError, isLoading }] = usePutEntranceFeeMutation();

    const { hasShiftRequirement } = useShift();

    const update = () => {
        putEntranceFee({
            ...computeEntranceFeeForQuery(entranceFee),
            entranceFeeId: parseInt(entranceFeeId, 10),
            brandId: parseInt(brandId, 10),
            restaurants: formatRestaurantsForQuery(
                entranceFeeRestaurants,
                data as EntranceFeeType,
                entranceFee,
                hasShiftRequirement,
            ),
            token,
        });
    };

    useEffect(() => {
        if (data !== undefined) {
            const computedRestaurants = computeRestaurantCustomisations(data.restaurants, data, hasShiftRequirement);
            dispatch(updateEntranceFee(amountToEuro(data)));
            dispatch(updateEntranceFeeRestaurants(computedRestaurants));
        }
    }, [data, dispatch, hasShiftRequirement]);

    if (data === undefined) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <EntranceFeeForm
            entranceFee={amountToEuro(data)}
            error={stringifyError(error) || stringifyError(putError)}
            updateSuccess={isPutSuccess}
            loading={isLoading}
            onSubmit={update}
        />
    );
};

export default EntranceFee;
