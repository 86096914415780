import React, { useCallback, useEffect } from 'react';
import { FormProvider, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PricingConfigurationSection from '@/pages/Customer/PricingConfigurationSection';
import { computeText } from '@/locales/utils';
import { Text } from '@/components/atoms/Text';
import TextFieldInput from '@/pages/Customer/CustomerFormInputs/TextFieldInput';
import PasswordInput from '@/pages/Customer/CustomerFormInputs/PasswordInput';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';
import { getUseCustomerFormAutoGenerateEmailAndPassword } from '@/redux/launchDarkly';
import { AddBadgeButton, FlexContainer, GeneralInformationContainer } from './CreateCustomerForm.style';
import { isValidPassword } from './CreateCustomerForm.utils';
import { BaseCustomerAttributes } from '../CreateCustomerModal.types';
import { isValidPhoneNumber } from 'libphonenumber-js';
import DateInput from '@/pages/Customer/CustomerFormInputs/DateInput';
import BadgeInput from '@/pages/Customer/CustomerFormInputs/BadgeInput';
import { generateBadgeNumber } from '@/pages/Customer/CustomerFormInputs/CreateCustomerModal.utils';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon.interface';
import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';
import { Badge } from '@/services/innovorder/customer/customer.types';

type CreateCustomerFormProps = {
    customerForm: UseFormReturn<BaseCustomerAttributes>;
    guestGroups: GuestGroupWithGuestsCount[];
    activeStudentEntities?: ActiveStudentEntities;
};

const CreateCustomerForm: React.FunctionComponent<React.PropsWithChildren<CreateCustomerFormProps>> = ({
    customerForm,
    guestGroups,
    activeStudentEntities,
}) => {
    const intl = useIntl();

    const customerFormAutoGenerateEmailAndPassword = useSelector(getUseCustomerFormAutoGenerateEmailAndPassword);
    const { fields, append, remove, update } = useFieldArray<BaseCustomerAttributes, 'badges'>({
        control: customerForm.control,
        name: 'badges',
        rules: {
            minLength: {
                value: 1,
                message: computeText(intl, 'field.required'),
            },
        },
    });

    const addBadge = useCallback(() => {
        const badgeNumber = generateBadgeNumber();
        append({ value: badgeNumber, type: '', isDefault: false });
    }, [append]);

    const deleteBadge = useCallback(
        (index: number) => {
            if (fields[index].isDefault) {
                update(0, { ...fields[0], isDefault: true });
            }
            remove(index);
        },
        [remove, update, fields],
    );
    const starBadge = useCallback(
        (index: number) => {
            const currentSelectedBadgeIndex = fields.findIndex((field) => field.isDefault);
            update(currentSelectedBadgeIndex, { ...fields[currentSelectedBadgeIndex], isDefault: false });
            update(index, { ...fields[index], isDefault: true });
        },
        [update, fields],
    );

    const { watch } = customerForm;

    return (
        <FormProvider {...customerForm}>
            <form>
                <FlexContainer>
                    <Text text={'customer.form.clientInfoSection'} weight="bold" />
                    <GeneralInformationContainer>
                        <TextFieldInput
                            fieldName="firstName"
                            rules={{
                                required: computeText(intl, 'field.required'),
                            }}
                        />
                        <TextFieldInput
                            fieldName="lastName"
                            rules={{
                                required: computeText(intl, 'field.required'),
                            }}
                        />
                        <TextFieldInput
                            fieldName="email"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                pattern: {
                                    value: /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i,
                                    message: computeText(intl, 'field.invalidEmail'),
                                },
                            }}
                            isTouched={customerFormAutoGenerateEmailAndPassword}
                        />
                        <TextFieldInput
                            fieldName="phoneNumber"
                            rules={{
                                validate: (phoneNumber: string) => {
                                    if (phoneNumber && !isValidPhoneNumber(phoneNumber, 'FR')) {
                                        return computeText(intl, 'field.invalidPhoneNumber');
                                    }
                                    return true;
                                },
                            }}
                        />
                        <PasswordInput
                            fieldName="password"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (password: string) => {
                                    if (!isValidPassword(password)) {
                                        return computeText(intl, 'field.invalidPassword');
                                    }
                                    return true;
                                },
                            }}
                            isTouched={customerFormAutoGenerateEmailAndPassword}
                        />
                        <PasswordInput
                            fieldName="confirmPassword"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (confirmPassword: string) => {
                                    if (watch('password') !== confirmPassword) {
                                        return computeText(intl, 'field.unmatchedPassword');
                                    }
                                    return true;
                                },
                            }}
                            isTouched={customerFormAutoGenerateEmailAndPassword}
                        />
                        <div style={{ gridColumn: 'span 2' }}>
                            <TextFieldInput fieldName="studentNumber" />
                        </div>
                        <DateInput fieldName="arrivalDate" />
                        <DateInput fieldName="departureDate" />
                        {fields.map((field, index) => (
                            <BadgeInput
                                starBadge={starBadge}
                                deleteBadge={deleteBadge}
                                key={field.id}
                                badge={{ ...field, index } as unknown as Badge & { index: number }}
                            />
                        ))}

                        <AddBadgeButton type="button" onClick={addBadge}>
                            <SVGIcon icon={SVGIcons.PLUS} size={18} />
                            <Text text={'customer.form.addBadge'} weight="bold" />
                        </AddBadgeButton>
                    </GeneralInformationContainer>
                    <PricingConfigurationSection
                        guestGroups={guestGroups || []}
                        activeStudentEntities={activeStudentEntities}
                    />
                </FlexContainer>
            </form>
        </FormProvider>
    );
};

export default CreateCustomerForm;
