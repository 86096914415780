import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { Cell } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getGrant, updateGrant } from '@/redux/grantAndEntranceFee';

import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { GrantStrategy, RestaurantGrantPayload, ShiftGrantPayloadData } from '@/services/innovorder/grant/grant.type';
import { useGetRestaurantShiftsQuery } from '@/services/innovorder/restaurant/restaurant.endpoints';
import { usePostGrantMutation, usePutGrantMutation } from '@/services/innovorder/grant/grant.endpoints';
import { Routes } from '@/core/router/routes.types';

import { GrantShiftsProps } from './GrantShifts';
import {
    areShiftGrantsValid,
    buildGrant,
    buildGrantRestaurants,
    computeGrantShiftsColumns,
    computeGrantShiftsRows,
} from './utils';

type RestaurantGrantForm = {
    dailyLimit: number;
    strategy: GrantStrategy;
    shiftGrants: ShiftGrantPayloadData[];
};

export const useGrantShiftsVM = ({ restaurantId, grantType, name, isCreateMode, handleOnHide }: GrantShiftsProps) => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const grant = useSelector(getGrant);

    const [rows, setRows] = useState<TableRow[]>([]);
    const columns = computeGrantShiftsColumns(intl);

    const [postGrant, { isLoading: isPostLoading, isSuccess, data }] = usePostGrantMutation();
    const [putGrant, { isLoading: isPutLoading }] = usePutGrantMutation();
    const { data: shifts } = useGetRestaurantShiftsQuery({
        restaurantId: restaurantId.toString(),
    });

    const history = useHistory();

    const restaurantGrant = useMemo(() => {
        return (
            grant?.restaurants?.find((restaurant) => restaurant.restaurantId === restaurantId)?.restaurant_grant ?? {
                dailyLimit: grant?.dailyLimit ?? null,
                strategy: grant?.strategy ?? GrantStrategy.MinimumAmountAndDailyReached,
            }
        );
    }, [grant, restaurantId]);

    const shiftGrants = useMemo(() => {
        return grant?.restaurants?.find((restaurant) => restaurant.restaurantId === restaurantId)?.shiftGrants ?? [];
    }, [grant, restaurantId]);

    const {
        control: controlRestaurantGrant,
        getValues,
        setValue,
        setError,
    } = useForm<RestaurantGrantForm>({
        defaultValues: {
            strategy: GrantStrategy.MinimumAmountAndDailyReached,
            dailyLimit: 999,
            shiftGrants: [],
        },
    });

    if (isSuccess && data?.grantId) {
        history.push(generatePath(Routes.Grant, { brandId: grant.brandId, grantId: data.grantId }));
    }

    const handleOnRowClick = (cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
        if (!cell) return;

        if (cell.column.id === 'isEnabled') return;
        if (
            cell.row.values.isEnabled.value.value &&
            (cell.column.id === 'amount' || cell.column.id === 'threshold' || cell.column.id === 'ceiling')
        )
            return;

        cell.row.values.isEnabled.value.onChange();
    };

    const handleOnChange = useCallback(
        (
            shiftId: string,
            attributes: 'amount' | 'threshold' | 'ceiling' | 'isEnabled',
            value: number | null,
            shift: { name: string; isDefault: boolean },
        ) => {
            if (!shifts || !grant) return;

            const formShiftGrants = getValues('shiftGrants');
            const uploadedShiftGrant = formShiftGrants.find((shiftGrant) => shiftGrant.shiftId === shiftId);

            // CASE 1 - shiftGrant is disabled
            if (uploadedShiftGrant && attributes === 'isEnabled') {
                setValue(
                    'shiftGrants',
                    formShiftGrants.filter((shiftGrant) => shiftGrant.shiftId !== shiftId),
                );
            }

            // CASE 2 - shiftGrant was already enabled and is being updated
            if (uploadedShiftGrant && attributes !== 'isEnabled') {
                uploadedShiftGrant[attributes] = value as number;
                setValue(
                    'shiftGrants',
                    formShiftGrants.map((shiftGrant) =>
                        shiftGrant.shiftId === shiftId ? uploadedShiftGrant : shiftGrant,
                    ),
                );
            }

            // CASE 3 - shiftGrant is enabled
            if (!uploadedShiftGrant && attributes === 'isEnabled') {
                setValue(
                    'shiftGrants',
                    formShiftGrants
                        .map((shiftGrant) => shiftGrant)
                        .concat([
                            {
                                shiftId,
                                shift,
                                amount: grant.amount * CURRENCY_RATE,
                                threshold: grant.threshold ? grant.threshold * CURRENCY_RATE : null,
                                ceiling: grant.ceiling ? grant.ceiling * CURRENCY_RATE : null,
                            },
                        ]),
                );
            }

            setRows(
                computeGrantShiftsRows({
                    shifts,
                    shiftGrants: getValues('shiftGrants'),
                    defaultValues: {
                        amount: grant.amount,
                        threshold: grant.threshold ?? null,
                        ceiling: grant.ceiling ?? null,
                    },
                    intl,
                    grantType,
                    handleOnChange,
                }),
            );
        },
        [getValues, grant, intl, setValue, shifts, grantType],
    );

    const handleOnSubmit = async () => {
        if (getValues('dailyLimit') > grant.dailyLimit) {
            setError('dailyLimit', {});
            return;
        }

        if (!areShiftGrantsValid(grantType, getValues('shiftGrants'))) {
            return;
        }

        const baseGrantPayload = buildGrant({ grant });
        const updatedShiftGrants = getValues('shiftGrants');

        let updatedRestaurants: RestaurantGrantPayload[] = [];
        updatedRestaurants = buildGrantRestaurants({
            grant,
            updatedRestaurant: {
                restaurantId,
                restaurantName: name,
                strategy: getValues('strategy'),
                dailyLimit: getValues('dailyLimit'),
            },
            updatedShiftGrants,
        });

        const updatedGrant = {
            ...baseGrantPayload,
            restaurants: updatedRestaurants,
        };

        if (isCreateMode) {
            await postGrant(updatedGrant);
        }

        if (!isCreateMode) {
            await putGrant(updatedGrant);
        }

        dispatch(updateGrant(updatedGrant));
        handleOnHide();
    };

    useEffect(() => {
        if (restaurantGrant && getValues('dailyLimit') !== restaurantGrant.dailyLimit) {
            setValue('dailyLimit', restaurantGrant.dailyLimit);
        }

        if (restaurantGrant && getValues('strategy') !== restaurantGrant.strategy) {
            setValue('strategy', restaurantGrant.strategy);
        }
    }, [restaurantGrant, getValues, setValue]);

    useEffect(() => {
        if (shiftGrants && getValues('shiftGrants')?.length === 0) {
            const initialShiftGrants = shiftGrants.map(({ shiftId, amount, threshold, ceiling, shift }) => {
                return { shiftId, amount, threshold, ceiling, shift };
            });

            setValue('shiftGrants', initialShiftGrants);
        }
    }, [shiftGrants, getValues, setValue]);

    useEffect(() => {
        if (grant && rows.length === 0 && shifts && shiftGrants) {
            setRows(
                computeGrantShiftsRows({
                    shifts,
                    shiftGrants,
                    defaultValues: {
                        amount: grant.amount,
                        threshold: grant.threshold ?? null,
                        ceiling: grant.ceiling ?? null,
                    },
                    intl,
                    grantType,
                    handleOnChange,
                }),
            );
        }
    }, [shifts, intl, grant, rows.length, getValues, handleOnChange, shiftGrants, grantType]);

    return {
        controlRestaurantGrant,
        columns,
        rows,
        isLoading: isPostLoading || isPutLoading,
        dailyLimitMax: grant?.dailyLimit ?? 99999,

        handleOnRowClick,
        handleOnChange,
        handleOnSubmit,
    };
};
