import React from 'react';
import { IntlShape } from 'react-intl';
import { Column } from 'react-table';

import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { TableRow } from '@/components/atoms/Table/Table.type';

import {
    BaseGrantCreateAttributes,
    GrantPayload,
    GrantStrategy,
    GrantType,
    PutGrantPayload,
    RestaurantGrantPayload,
    ShiftGrantPayloadData,
} from '@/services/innovorder/grant/grant.type';
import { Shift } from '@/services/innovorder/shift/shift.type';
import { computeText } from '@/locales/utils';

const dayOrder = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const computeDaysOfTheWeek = (intl: IntlShape, days: string): string => {
    const dayList = days.split(',');

    if (dayList.length === 1) {
        return intl.formatMessage({ id: `shift.days.${dayList[0]}` });
    }

    if (dayList.length === 7) {
        return intl.formatMessage({ id: 'shift.allDays' });
    }

    const indices = dayList.map((day) => dayOrder.indexOf(day)).sort((a, b) => a - b);
    const isConsecutive = indices.every((value, index) => index === 0 || value === indices[index - 1] + 1);

    if (isConsecutive) {
        const start = intl.formatMessage({ id: `shift.days.${dayList[0]}` });
        const end = intl.formatMessage({ id: `shift.days.${dayList[dayList.length - 1]}` });
        return intl.formatMessage({ id: 'shift.period' }, { start, end });
    }

    const computedDays = dayList.map((day) => intl.formatMessage({ id: `shift.days.${day}` }));
    return computedDays.join(', ');
};

export const computeTime = ({ start, end }: { start: number; end: number }): string => {
    const startHours = Math.floor(start / 60);
    const endHours = Math.floor(end / 60) % 24;
    const startMins = start % 60;
    const endMins = end % 60;

    const formattedStartHours = startHours.toString().padStart(2, '0');
    const formattedEndHours = endHours.toString().padStart(2, '0');
    const formattedStartMinutes = startMins.toString().padStart(2, '0');
    const formattedEndMinutes = endMins.toString().padStart(2, '0');

    return `${formattedStartHours}:${formattedStartMinutes} - ${formattedEndHours}:${formattedEndMinutes}`;
};

export const computeGrantShiftsColumns = (intl: IntlShape): Column<TableRow>[] => {
    return [
        {
            Header: computeText(intl, 'grant.configuration.table.activation'),
            accessor: 'isEnabled',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'grant.configuration.table.name'),
            accessor: 'name',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'grant.configuration.table.time'),
            accessor: 'time',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'grant.configuration.table.days'),
            accessor: 'daysOfWeek',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'grant.configuration.table.amount'),
            accessor: 'amount',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'grant.configuration.table.threshold'),
            accessor: 'threshold',
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'grant.configuration.table.ceiling'),
            accessor: 'ceiling',
            disableSortBy: true,
        },
    ];
};

export const computeGrantShiftsRows = ({
    shifts,
    shiftGrants,
    defaultValues,
    intl,
    grantType,
    handleOnChange,
}: {
    shifts: Shift[];
    shiftGrants: ShiftGrantPayloadData[];
    defaultValues: Pick<BaseGrantCreateAttributes, 'amount' | 'threshold' | 'ceiling'>;
    intl: IntlShape;
    grantType: GrantType;
    handleOnChange: (
        shiftId: string,
        attributes: 'amount' | 'threshold' | 'ceiling' | 'isEnabled',
        value: number | null,
        shift: { name: string; isDefault: boolean },
    ) => void;
}): TableRow[] => {
    const computeAmountError = (amount: number) => {
        if (Number.isNaN(amount)) {
            return computeText(intl, 'shift.amount.required');
        }
        if (amount < 0) {
            return computeText(intl, 'shift.amount.positive');
        }

        return undefined;
    };

    const computeCeilingError = (ceiling: number | null | undefined) => {
        if (grantType === 'RATE' && (Number.isNaN(ceiling) || ceiling === null || ceiling === undefined)) {
            return computeText(intl, 'shift.ceiling.required');
        }

        if (ceiling && ceiling < 0) {
            return computeText(intl, 'shift.ceiling.positive');
        }

        return undefined;
    };

    return shifts.map((shift) => {
        const enabledGrant = shiftGrants.find((shiftGrant) => shiftGrant.shiftId === shift.shiftId);

        const amount = enabledGrant ? enabledGrant.amount : defaultValues.amount;
        const threshold = enabledGrant ? enabledGrant.threshold : defaultValues.threshold;
        const ceiling = enabledGrant ? enabledGrant.ceiling : defaultValues.ceiling;

        return {
            isEnabled: {
                type: 'switch',
                value: {
                    value: !!enabledGrant,
                    disabled: false,
                    onChange: () => {
                        handleOnChange(shift.shiftId, 'isEnabled', null, {
                            name: shift.name,
                            isDefault: shift.isDefault,
                        });
                    },
                },
            },
            name: {
                type: 'string',
                value: {
                    text: shift.isDefault ? computeText(intl, 'grant.configuration.table.defaultShift') : shift.name,
                },
            },
            time: {
                type: 'computedNumber',
                value: { text: computeTime({ start: shift.start, end: shift.end }), number: shift.start },
            },
            daysOfWeek: { type: 'string', value: { text: computeDaysOfTheWeek(intl, shift.daysOfWeek) } },
            amount: !enabledGrant
                ? { type: 'disabled', value: false }
                : {
                      type: 'input',
                      value: {
                          unitType: grantType === 'ABSOLUTE' ? 'currency' : 'percentage',
                          type: 'number',
                          min: 0,
                          value: (amount / CURRENCY_RATE).toString(),
                          error: computeAmountError(amount),
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                              const { value } = event.target;
                              handleOnChange(enabledGrant.shiftId, 'amount', parseFloat(value) * CURRENCY_RATE, {
                                  name: shift.name,
                                  isDefault: shift.isDefault,
                              });
                          },
                      },
                  },
            threshold: !enabledGrant
                ? { type: 'disabled', value: false }
                : {
                      type: 'input',
                      value: {
                          type: 'number',
                          min: 0,
                          unitType: 'currency',
                          value: threshold ? (threshold / CURRENCY_RATE).toString() : undefined,
                          error: threshold && threshold < 0 ? computeText(intl, 'shift.threshold.positive') : undefined,
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                              const { value } = event.target;
                              handleOnChange(enabledGrant.shiftId, 'threshold', parseFloat(value) * CURRENCY_RATE, {
                                  name: shift.name,
                                  isDefault: shift.isDefault,
                              });
                          },
                      },
                  },
            ceiling:
                !enabledGrant || grantType === 'ABSOLUTE'
                    ? { type: 'disabled', value: false }
                    : {
                          type: 'input',
                          value: {
                              type: 'number',
                              unitType: 'currency',
                              value: ceiling ? (ceiling / CURRENCY_RATE).toString() : undefined,
                              error: computeCeilingError(ceiling),
                              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  const { value } = event.target;
                                  handleOnChange(enabledGrant.shiftId, 'ceiling', parseFloat(value) * CURRENCY_RATE, {
                                      name: shift.name,
                                      isDefault: shift.isDefault,
                                  });
                              },
                          },
                      },
        };
    });
};

export const buildGrant = ({ grant }: { grant: GrantPayload }): PutGrantPayload => {
    return {
        ...grant,
        amount: grant.amount * CURRENCY_RATE,
        threshold: grant.threshold ? grant.threshold * CURRENCY_RATE : null,
        ceiling: grant.ceiling ? grant.ceiling * CURRENCY_RATE : null,
    };
};

export const buildGrantRestaurants = ({
    grant,
    updatedRestaurant,
    updatedShiftGrants,
}: {
    grant: GrantPayload;
    updatedRestaurant: {
        restaurantId: number;
        restaurantName: string;
        strategy: GrantStrategy;
        dailyLimit: number;
    };
    updatedShiftGrants: ShiftGrantPayloadData[];
}): RestaurantGrantPayload[] => {
    const isFirstActivation = !grant.restaurants.some(
        ({ restaurantId }) => restaurantId === updatedRestaurant.restaurantId,
    );

    const filteredRestaurants =
        updatedShiftGrants.length === 0
            ? grant.restaurants.filter((restaurant) => restaurant.restaurantId !== updatedRestaurant.restaurantId)
            : grant.restaurants;

    const updatedRestaurants = filteredRestaurants.map((restaurant) => {
        return restaurant.restaurantId === updatedRestaurant.restaurantId
            ? {
                  ...restaurant,
                  restaurant_grant: {
                      ...restaurant.restaurant_grant,
                      // should we not update amount, ceiling and threshold here ? to keep consistency if FF is deactivated ?
                      strategy: updatedRestaurant.strategy,
                      dailyLimit: updatedRestaurant.dailyLimit,
                      status: 'customised',
                  },
                  shiftGrants: updatedShiftGrants.map(({ shiftId, amount, threshold, ceiling, shift }) => {
                      return {
                          grantId: grant.grantId,
                          restaurantId: updatedRestaurant.restaurantId,
                          shiftId,
                          shift,
                          amount,
                          threshold,
                          ceiling,
                      };
                  }),
              }
            : restaurant;
    });

    if (isFirstActivation) {
        updatedRestaurants.push({
            restaurantId: updatedRestaurant.restaurantId,
            name: updatedRestaurant.restaurantName,
            restaurant_grant: {
                grantId: grant.grantId,
                restaurantId: updatedRestaurant.restaurantId,
                amount: grant.amount * CURRENCY_RATE,
                // should we not keep ceiling and threshold here ? to keep consistency if FF is deactivated ?
                strategy: updatedRestaurant.strategy,
                dailyLimit: updatedRestaurant.dailyLimit,
                status: 'customised',
            },
            shiftGrants: updatedShiftGrants.map(({ shiftId, amount, threshold, ceiling, shift }) => {
                return {
                    grantId: grant.grantId,
                    restaurantId: updatedRestaurant.restaurantId,
                    shift,
                    shiftId,
                    amount,
                    threshold,
                    ceiling,
                };
            }),
        });
    }

    return updatedRestaurants;
};

export const areShiftGrantsValid = (grantType: GrantType, shiftGrants: ShiftGrantPayloadData[]): boolean => {
    let isValid = true;

    shiftGrants.forEach((shiftGrant) => {
        if (
            Number.isNaN(shiftGrant.amount) ||
            shiftGrant.amount < 0 ||
            (shiftGrant.threshold && shiftGrant.threshold < 0) ||
            (shiftGrant.ceiling && shiftGrant.ceiling < 0) ||
            (grantType === 'RATE' && !shiftGrant.ceiling)
        ) {
            isValid = false;
        }
    });

    return isValid;
};
