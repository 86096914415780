import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import React from 'react';
import { Input, InputSuggestions } from '@/components/atoms/Form/Input';

type TextFieldValues = Record<string, string>;

type TextFieldInputProps = {
    rules?: RegisterOptions<TextFieldValues, keyof TextFieldValues>;
    fieldName: string;
    isTouched?: boolean;
    suggestions?: InputSuggestions;
};

const DateInput: React.FunctionComponent<React.PropsWithChildren<TextFieldInputProps>> = ({
    rules,
    fieldName,
    isTouched,
    suggestions,
}) => {
    const {
        control,
        formState: { touchedFields },
    } = useFormContext();

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={rules}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
                const isFieldTouched = isTouched || touchedFields[fieldName] || !!value;
                return (
                    <Input
                        error={error?.message}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={`customer.form.${fieldName}`}
                        labelId={`customer.form.${fieldName}`}
                        isTouched={isFieldTouched}
                        ref={ref}
                        suggestions={suggestions}
                        type="date"
                    />
                );
            }}
        />
    );
};

export default DateInput;
