import React from 'react';
import { IntlShape } from 'react-intl';
import { Column } from 'react-table';

import { Theme } from '@emotion/react';
import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import {
    CompleteEntranceFeeRestaurantCustomisations,
    CustomisationStatus,
    EntranceFeeRestaurantCustomisations,
    sortedStatusMap,
} from '@/redux/grantAndEntranceFee';
import {
    BaseEntranceFeeAttributes,
    BaseRestaurantEntranceFeeData,
    EntranceFee,
    EntranceFeePayload,
    EntranceFeeStrategy,
    RestaurantEntranceFeeCreateAttribute,
    RestaurantEntranceFeePayload,
    ShiftEntranceFeePayloadData,
    VatEnum,
} from '@/services/innovorder/entranceFee/entranceFee.type';
import { Restaurant } from '@/services/innovorder/restaurant/restaurant.type';
import { Option, SelectProps } from '../../../../../components/atoms/Select/Select';
import { EntranceFeeStrategyTranslationMap, entranceFeeStrategyMap, vatMap } from '../EntranceFeeForm';

export const emptyStatus = ['disabled', 'enabled'];

export const statusOptionMap: Option<CustomisationStatus>[] = [
    {
        value: 'enabled',
        label: 'select.enabled',
    },
    {
        value: 'disabled',
        label: 'select.disabled',
    },
    {
        value: 'customised',
        label: 'select.customised',
    },
];

export const computeEntranceFeeRestaurantTableRowsVM = ({
    restaurants,
    theme,
    intl,
    isCreateMode,
    onChange,
    handleOnConfigure,
}: {
    restaurants: CompleteEntranceFeeRestaurantCustomisations[];
    theme: Theme;
    intl: IntlShape;
    isCreateMode?: boolean;
    onChange: (restaurantId: number, update: Partial<EntranceFeeRestaurantCustomisations>) => void;
    handleOnConfigure: ({ restaurantId, name }: { restaurantId: number; name: string }) => void;
}): TableRow[] =>
    restaurants.map(({ status, name, restaurantId, amount, dailyLimit, strategy, vat, shifts }) => {
        const baseData: TableRow = {
            status: {
                type: 'badge',
                value: {
                    backgroundColor: theme.savory.colors.secondary[200],
                    text: `entranceFee.table.state.${status}`,
                    textColor: 'black',
                    fontWeight: 'bold',
                },
            },
            oldStatus: {
                type: 'select',
                value: {
                    value: status,
                    options: statusOptionMap,
                    onChange: (value) => onChange(restaurantId, { status: value }),
                } as SelectProps<CustomisationStatus>,
            },
            restaurant: {
                type: 'string',
                value: { text: name, opacity: status === 'disabled' ? theme.opacity.disabled : theme.opacity.visible },
            },
            editAction: {
                type: 'action',
                value: {
                    children: computeText(intl, 'button.configure'),
                    buttonType: 'red',
                    hasBorder: true,
                    disabled: !!isCreateMode,
                    onClick: () => handleOnConfigure({ restaurantId, name }),
                },
            },
        };

        if (emptyStatus.includes(status)) {
            return {
                ...baseData,
                shifts:
                    status === 'enabled'
                        ? {
                              type: 'string',
                              value: {
                                  text: shifts,
                              },
                          }
                        : {
                              type: 'empty',
                              value: '',
                          },
                amount: {
                    type: 'empty',
                    value: '',
                },
                dailyLimit: {
                    type: 'empty',
                    value: '',
                },
                strategy: {
                    type: 'empty',
                    value: '',
                },
                oldDailyLimit: {
                    type: 'empty',
                    value: '',
                },
                oldStrategy: {
                    type: 'empty',
                    value: '',
                },
                vat: {
                    type: 'empty',
                    value: '',
                },
            };
        }

        return {
            ...baseData,
            shifts: {
                type: 'string',
                value: {
                    text: shifts,
                },
            },
            amount: {
                type: 'input',
                value: {
                    unitType: 'currency',
                    value: amount?.toString(),
                    type: 'number',
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(restaurantId, { amount: parseFloat(event.target.value) }),
                },
            },
            dailyLimit: {
                type: 'string',
                value: {
                    text: dailyLimit?.toString(),
                },
            },
            strategy: {
                type: 'string',
                value: {
                    text: EntranceFeeStrategyTranslationMap[strategy],
                },
            },
            oldDailyLimit: {
                type: 'input',
                value: {
                    value: dailyLimit?.toString(),
                    type: 'number',
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(restaurantId, { dailyLimit: parseInt(event.target.value, 10) }),
                },
            },
            oldStrategy: {
                type: 'select',
                value: {
                    value: strategy,
                    options: entranceFeeStrategyMap,
                    onChange: (value) => onChange(restaurantId, { strategy: value }),
                } as SelectProps<EntranceFeeStrategy>,
            },
            vat: {
                type: 'select',
                value: {
                    value: vat,
                    options: vatMap,
                    onChange: (value) => onChange(restaurantId, { vat: value }),
                } as SelectProps<VatEnum>,
            },
        };
    });

export const computeEntranceFeeRestaurantTableColumnsVM = (
    intl: IntlShape,
    useShift: boolean,
): readonly Column<TableRow>[] =>
    useShift
        ? [
              {
                  Header: computeText(intl, 'entranceFee.table.state'),
                  accessor: 'status',
                  disableSortBy: true,
                  minWidth: 300,
              },
              {
                  Header: computeText(intl, 'entranceFee.restaurant'),
                  accessor: 'restaurant',
              },
              {
                  Header: computeText(intl, 'entranceFee.table.services'),
                  accessor: 'shifts',
                  disableSortBy: true,
                  minWidth: 220,
              },
              {
                  Header: computeText(intl, 'entranceFee.strategy'),
                  accessor: 'strategy',
                  disableSortBy: true,
                  minWidth: 180,
              },
              {
                  Header: computeText(intl, 'entranceFee.dailyLimit'),
                  accessor: 'dailyLimit',
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'entranceFee.action'),
                  accessor: 'editAction',
                  disableSortBy: true,
              },
          ]
        : [
              {
                  Header: computeText(intl, 'switch.activate'),
                  accessor: 'oldStatus',
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'entranceFee.restaurants'),
                  accessor: 'restaurant',
              },
              {
                  Header: computeText(intl, 'entranceFee.amount'),
                  accessor: 'amount',
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'entranceFee.dailyLimit'),
                  accessor: 'oldDailyLimit',
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'entranceFee.strategy'),
                  accessor: 'oldStrategy',
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'entranceFee.vat'),
                  accessor: 'vat',
                  disableSortBy: true,
              },
          ];

export const generateDefaultRestaurantEntranceFee = (
    { amount, dailyLimit, strategy, vat }: BaseEntranceFeeAttributes,
    { restaurantId, name }: Restaurant,
    shiftEntranceFees: any[],
    defaultShiftName: string,
): CompleteEntranceFeeRestaurantCustomisations => {
    return {
        restaurantId,
        amount,
        dailyLimit,
        strategy,
        vat,
        status: 'disabled',
        shifts: shiftEntranceFees.map(({ shift }) => (shift.isDefault ? defaultShiftName : shift.name)).join(', '),
        name,
    };
};

export const generateRestaurantEntranceFee = (
    customisation: EntranceFeeRestaurantCustomisations,
    entranceFee: BaseEntranceFeeAttributes,
    shiftEntranceFees: ShiftEntranceFeePayloadData[] = [],
    defaultShiftName = '',
): CompleteEntranceFeeRestaurantCustomisations => {
    return {
        vat: entranceFee.vat,
        amount: entranceFee.amount,
        dailyLimit: entranceFee.dailyLimit,
        strategy: entranceFee.strategy,
        shifts: shiftEntranceFees.map(({ shift }) => (shift.isDefault ? defaultShiftName : shift.name)).join(', '),
        ...customisation,
    };
};

export const computeEnabledRestaurant = ({
    restaurant,
    enabledRestaurants,
    entranceFee,
    intl,
    hasShiftRequirement,
}: {
    restaurant: Restaurant;
    enabledRestaurants: EntranceFeeRestaurantCustomisations[];
    entranceFee: EntranceFeePayload;
    intl: IntlShape;
    hasShiftRequirement: boolean;
}): CompleteEntranceFeeRestaurantCustomisations => {
    const enabledRestaurant = enabledRestaurants.find((r) => r.restaurantId === restaurant.restaurantId);
    const shiftEntranceFees =
        entranceFee.restaurants.find((r) => r.restaurantId === restaurant.restaurantId)?.shiftEntranceFees ?? [];
    const defaultShiftName = computeText(intl, 'shift.default');

    return enabledRestaurant && (hasShiftRequirement ? shiftEntranceFees.length : true)
        ? generateRestaurantEntranceFee(enabledRestaurant, entranceFee, shiftEntranceFees, defaultShiftName)
        : generateDefaultRestaurantEntranceFee(entranceFee, restaurant, shiftEntranceFees, defaultShiftName);
};

export const amountToEuro = <T extends { amount: number }>(object: T): T => ({
    ...object,
    amount: object.amount / CURRENCY_RATE,
});

export const amountToCents = <T extends { amount: number }>(object: T): T => ({
    ...object,
    amount: object.amount * CURRENCY_RATE,
});

export const computeRestaurantsCreateAttributes = ({
    restaurants,
    enabledRestaurants = [],
    entranceFee,
    intl,
    hasShiftRequirement,
}: {
    restaurants: Restaurant[];
    enabledRestaurants?: EntranceFeeRestaurantCustomisations[];
    entranceFee?: EntranceFeePayload;
    intl: IntlShape;
    hasShiftRequirement: boolean;
}): CompleteEntranceFeeRestaurantCustomisations[] => {
    if (!entranceFee) return [];

    const computedRestaurants = restaurants.map((restaurant) =>
        computeEnabledRestaurant({ restaurant, enabledRestaurants, entranceFee, intl, hasShiftRequirement }),
    );

    return computedRestaurants.sort((a, b) => sortedStatusMap[a.status] - sortedStatusMap[b.status]);
};

export const udpateEnabledRestaurants = ({
    enabledRestaurants,
    allRestaurants,
    restaurantId,
    updates,
}: {
    enabledRestaurants: EntranceFeeRestaurantCustomisations[];
    allRestaurants: Restaurant[];
    restaurantId: number;
    updates: Partial<EntranceFeeRestaurantCustomisations>;
}): EntranceFeeRestaurantCustomisations[] => {
    if (updates.status === 'disabled') {
        return enabledRestaurants.filter((r) => r.restaurantId !== restaurantId);
    }

    const enabledRestaurant = enabledRestaurants.find((r) => r.restaurantId === restaurantId);

    if (!enabledRestaurant && updates.status) {
        const restaurant = allRestaurants.find((r) => r.restaurantId === restaurantId);
        return [
            ...enabledRestaurants,
            {
                restaurantId,
                name: restaurant?.name || '',
                status: updates.status,
            },
        ];
    }

    if (enabledRestaurant && updates.status === 'enabled') {
        return enabledRestaurants.map((restaurant) => {
            if (restaurant.restaurantId !== restaurantId) {
                return restaurant;
            }

            return {
                restaurantId,
                name: restaurant.name,
                status: 'enabled',
            };
        });
    }

    return enabledRestaurants.map((restaurant) => {
        if (restaurant.restaurantId !== restaurantId) {
            return restaurant;
        }

        return {
            ...restaurant,
            ...updates,
        };
    });
};

const restaurantKeyToCheck: (keyof BaseRestaurantEntranceFeeData)[] = ['amount', 'dailyLimit', 'strategy', 'vat'];
const restaurantKeyToCheckWithShifts: (keyof BaseRestaurantEntranceFeeData)[] = ['strategy', 'vat'];
const shiftKeyToCheck = ['amount', 'vat'] as const;

export const isRestaurantCustom = (
    restaurant: BaseRestaurantEntranceFeeData,
    entranceFee: BaseEntranceFeeAttributes,
    useShift: boolean,
    shiftEntranceFees?: ShiftEntranceFeePayloadData[],
): boolean => {
    const isRestaurantEntranceFeeCustom = useShift
        ? restaurantKeyToCheckWithShifts.some((key) => {
              return entranceFee[key] !== restaurant[key];
          })
        : restaurantKeyToCheck.some((key) => {
              return entranceFee[key] !== restaurant[key];
          });

    const isShiftEntranceFeeCustom = shiftEntranceFees
        ? shiftEntranceFees.some((shiftEntranceFee) =>
              shiftKeyToCheck.some((key) => {
                  return shiftEntranceFee[key] && entranceFee[key] !== shiftEntranceFee[key];
              }),
          )
        : false;

    return isRestaurantEntranceFeeCustom || isShiftEntranceFeeCustom;
};

export const computeRestaurantCustomisations = (
    restaurants: RestaurantEntranceFeePayload[],
    entranceFee: EntranceFeePayload,
    useShift: boolean,
): EntranceFeeRestaurantCustomisations[] => {
    return restaurants.map(({ restaurantId, restaurant_entrance_fee, name }) => {
        const shiftEntranceFees = entranceFee.restaurants.find(
            (r) => r.restaurantId === restaurantId,
        )?.shiftEntranceFees;
        const isCustom = isRestaurantCustom(restaurant_entrance_fee, entranceFee, useShift, shiftEntranceFees);

        return {
            status: isCustom ? 'customised' : 'enabled',
            ...(isCustom ? amountToEuro(restaurant_entrance_fee) : {}),
            restaurantId,
            name,
        };
    });
};

export const computeEntranceFeeForQuery = (entranceFee: BaseEntranceFeeAttributes): BaseEntranceFeeAttributes => {
    return {
        ...amountToCents(entranceFee),
        code: entranceFee.code.trim(),
    };
};

export const mapRestaurantCustomisationToCreateAttributes = ({
    restaurantId,
    name,
    vat,
    amount,
    strategy,
    dailyLimit,
}: CompleteEntranceFeeRestaurantCustomisations): RestaurantEntranceFeeCreateAttribute => {
    return {
        restaurantId,
        name,
        restaurant_entrance_fee: {
            vat,
            amount,
            strategy,
            dailyLimit,
        },
    };
};

export const computeAndSpreadShiftEntranceFees = ({
    restaurantId,
    fetchedEntranceFee,
    formEntranceFee,
}: {
    restaurantId: number;
    fetchedEntranceFee: EntranceFee | undefined;
    formEntranceFee: EntranceFeePayload;
}): ShiftEntranceFeePayloadData[] => {
    const { shiftEntranceFees } = formEntranceFee.restaurants?.find((r) => r.restaurantId === restaurantId) ?? {
        shiftEntranceFees: [],
    };

    const updatedShiftEntranceFees =
        shiftEntranceFees?.map((shiftEntranceFee) => {
            if (
                fetchedEntranceFee &&
                shiftEntranceFee.shift.isDefault &&
                shiftEntranceFee.amount === fetchedEntranceFee.amount &&
                shiftEntranceFee.vat === fetchedEntranceFee.vat
            ) {
                return {
                    ...shiftEntranceFee,
                    amount: formEntranceFee.amount * CURRENCY_RATE,
                    vat: formEntranceFee.vat,
                };
            }

            return shiftEntranceFee;
        }) ?? [];

    return updatedShiftEntranceFees;
};

export const formatRestaurantsForQuery = (
    restaurants: EntranceFeeRestaurantCustomisations[],
    fetchedEntranceFee: EntranceFee | undefined,
    entranceFee: EntranceFeePayload,
    useShift: boolean,
): RestaurantEntranceFeeCreateAttribute[] => {
    return restaurants.map((restaurant) => {
        const computedShiftEntranceFees = useShift
            ? computeAndSpreadShiftEntranceFees({
                  restaurantId: restaurant.restaurantId,
                  formEntranceFee: entranceFee,
                  fetchedEntranceFee,
              })
            : undefined;

        return {
            ...mapRestaurantCustomisationToCreateAttributes(
                amountToCents(generateRestaurantEntranceFee(restaurant, entranceFee)),
            ),
            shiftEntranceFees: computedShiftEntranceFees,
        };
    });
};
