import { useCallback } from 'react';
import { ENV } from '@/utils/env';
import { useSelector } from 'react-redux';
import { getUser } from '@/redux/user';
import { AppEnv } from '@/utils/types';
import useDebounce from '../useDebounce';

export const useBeamer = (): void => {
    const user = useSelector(getUser);
    const injectBeamerHeader = useCallback(() => {
        if (user?.email && user.firstName && user.lastName) {
            window.beamer_config = {
                product_id: ENV.BEAMER_ID,
                user_firstname: user.firstName,
                user_lastname: user.lastName,
                user_email: user.email,
                selector: '.beamerButton',
            };

            const beamerScriptElement = document.createElement('script');
            beamerScriptElement.type = 'text/javascript';
            beamerScriptElement.src = 'https://app.getbeamer.com/js/beamer-embed.js';
            beamerScriptElement.defer = true;

            if (ENV.DASHBOARD_ENV === AppEnv.Production) {
                document.head.appendChild(beamerScriptElement);
            }
        }
    }, [user]);

    useDebounce(injectBeamerHeader, 1000, [user]);
};
