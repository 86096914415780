import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { computeText } from '@/locales/utils';

import { Input } from '@/components/atoms/Form/Input';
import { Text } from '@/components/atoms/Text';
import { Option } from '@/components/atoms/Select';
import { SavingBar } from '@/components/atoms/SavingBar';
import { Label } from '@/components/atoms/Label';
import MultiSelect from '@/components/atoms/MultiSelect';

import {
    PatchPosAccessGroupBody,
    PostPosAccessGroupBody,
} from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.type';
import Loader from '@/components/atoms/Loader';
import { theme } from '@/core/theme';
import {
    Container,
    CustomTitle,
    Flex,
    FlexContainer,
    FormChildrens,
    FormContainer,
    MargedTitle,
    TitleContainer,
} from './PosGroupsForm.style';
import { usePosGroupFormVM } from './PosGroupsForm.viewmodel';

export interface PosRolesPanelProps {
    setValid?: (state: boolean) => void;
    setDirty?: (state: boolean) => void;
    error: string;
    loading: boolean;
    onSubmit: (arg: PatchPosAccessGroupBody) => void;
}

const PosGroupForm: React.FunctionComponent<React.PropsWithChildren<PosRolesPanelProps>> = ({
    onSubmit,
    error: callError,
    loading,
}: PosRolesPanelProps) => {
    const { posUsers, posDevices, isLoading, posGroup } = usePosGroupFormVM();

    const intl = useIntl();
    const fieldRequiredErrorText = 'required';

    const PosGroupFormResolver = yupResolver(
        Yup.object().shape({
            name: Yup.string().required(fieldRequiredErrorText),
            posUserIds: Yup.array().of(Yup.number()).required(fieldRequiredErrorText),
            posDeviceIds: Yup.array().of(Yup.number()).required(fieldRequiredErrorText),
        }),
    );
    const {
        control,
        handleSubmit,
        reset,
        formState: { dirtyFields },
    } = useForm<PostPosAccessGroupBody>({
        defaultValues: { ...posGroup },
        resolver: PosGroupFormResolver,
    });

    const posUsersOptions: Option<number>[] = posUsers.map(({ posUserId, name }) => ({
        label: name,
        value: posUserId,
    }));
    const posDevicesOptions: Option<number>[] = posDevices.map(({ posDeviceId, deviceName }) => ({
        label: deviceName,
        value: posDeviceId,
    }));

    const resetFormData = useCallback((): void => {
        if (posGroup) {
            reset(posGroup);
        }
    }, [posGroup, reset]);

    useEffect(() => {
        resetFormData();
    }, [isLoading, resetFormData]);

    const mapToPosUsersSelectOptions = (ids: number[]): Option<number>[] => {
        if (!posUsers) return [];
        return ids?.map((id) => {
            const user = posUsers.find((posUser) => posUser.posUserId === id);

            return { value: id, label: user?.name || '' };
        });
    };
    const mapToPosDevicesSelectOptions = (ids: number[]): Option<number>[] => {
        if (!posDevices) return [];
        return ids?.map((id) => {
            const user = posDevices.find((posDevice) => posDevice.posDeviceId === id);

            return { value: id, label: user?.deviceName || '' };
        });
    };

    return (
        <>
            <Container>
                {isLoading ? (
                    <Loader color={theme.color.primary} size={40} withContainer />
                ) : (
                    <form>
                        <FlexContainer>
                            <div style={{ flex: 1, paddingRight: 50 }}>
                                <TitleContainer>
                                    <CustomTitle
                                        text={posGroup ? 'posGroup.edit.form.title' : 'posGroup.create.form.title'}
                                    />
                                </TitleContainer>
                                <Text text="posGroup.form.description" />
                            </div>
                            <FormContainer data-testid="posRole-form">
                                <MargedTitle
                                    text={posGroup ? 'posGroup.form.update' : 'posGroup.form.creation'}
                                    type="small"
                                    weight="medium"
                                />
                                <Flex>
                                    <FormChildrens style={{ marginRight: 20 }}>
                                        <Controller
                                            control={control}
                                            name="name"
                                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                                                return (
                                                    <Input
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        labelId="posGroup.name.form.label"
                                                        placeholder="posGroup.name.form.placeholder"
                                                        error={error?.message}
                                                    />
                                                );
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="posUserIds"
                                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                                <>
                                                    <Label
                                                        labelId="posGroup.users.form.label"
                                                        inputId="posGroup.users.form.label"
                                                    />
                                                    <MultiSelect<number>
                                                        inputId="posGroup.users.form.label"
                                                        isMulti={true}
                                                        options={posUsersOptions}
                                                        onChange={(options) => {
                                                            onChange(options.map((option) => option.value));
                                                        }}
                                                        onBlur={onBlur}
                                                        placeholder={computeText(
                                                            intl,
                                                            'posGroup.users.form.placeholder',
                                                        )}
                                                        value={mapToPosUsersSelectOptions(value)}
                                                        error={error}
                                                    />
                                                </>
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name="posDeviceIds"
                                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                                <>
                                                    <Label
                                                        labelId="posGroup.devices.form.label"
                                                        inputId="posGroup.devices.form.label"
                                                    />
                                                    <MultiSelect<number>
                                                        inputId="posGroup.devices.form.label"
                                                        isMulti={true}
                                                        options={posDevicesOptions}
                                                        // getOptionValue={(option) => option.value}
                                                        onChange={(options) => {
                                                            onChange(options.map((option) => option.value));
                                                        }}
                                                        onBlur={onBlur}
                                                        placeholder={computeText(
                                                            intl,
                                                            'posGroup.devices.form.placeholder',
                                                        )}
                                                        value={mapToPosDevicesSelectOptions(value)}
                                                        error={error}
                                                    />
                                                </>
                                            )}
                                        />
                                    </FormChildrens>
                                </Flex>
                            </FormContainer>
                        </FlexContainer>
                    </form>
                )}
            </Container>
            <SavingBar
                onSave={handleSubmit(onSubmit)}
                error={callError}
                loading={loading}
                changesCount={Object.keys(dirtyFields).length}
                onCancel={resetFormData}
            />
        </>
    );
};

export default PosGroupForm;
