import { Theme, useTheme } from '@emotion/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import RestaurantSelector from '@/components/molecules/RestaurantSelector';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, fetchDataProps, Table } from '@/components/atoms/Table';
import { Routes } from '@/core/router/routes.types';
import { useGetOrdersMutation } from '@/services/innovorder/orders/order.endpoints';
import { getBrandType } from '@/redux/brand';
import { getAssertedUserBrandId, getUserRestaurants, getUserRole, getUserToken } from '@/redux/user';
import { UserRestaurant, UserRoles } from '@/services/innovorder/user/user.type';
import { BrandType } from '@/services/innovorder/brand/brand.type';
import { GetOrdersPayload, GetOrdersResponse } from '@/services/innovorder/orders/order.type';
import { ApiRequest } from '@/services/innovorder/request.types';
import { getParamFromQueryString, replaceParamInUrl } from '@/utils/history';
import ExportModal from './ExportModal';
import { Container } from './OrderList.style';
import {
    convertTableFiltersToQueryFilters,
    convertTableSortToQuerySort,
    getDefaultHiddenColumns,
    getOrdersTableColumnsVM,
    getOrdersTableRowsVM,
    getRestaurantsCondition,
} from './utils';
import { useGetExportButtons } from './hooks/useGetExportButtons';
import PageHeader from './OrderListHeader';

const defaultSortBy = [{ id: 'orderDate', desc: true }];

export interface OrderListProps {
    token: string | undefined;
    brandId?: number;
    restaurants?: UserRestaurant[] | undefined;
    userRole?: UserRoles;
    customerId?: number;
    fetchOrders: (params: ApiRequest<GetOrdersPayload>) => void;
    data?: GetOrdersResponse;
    isLoading: boolean;
    brandType?: BrandType;
    theme: Theme;
    headerProps: { title: string; subTitle: string; showRestaurantSelector: boolean };
}

export const OrderList: React.FunctionComponent<OrderListProps> = ({
    token,
    brandId,
    restaurants,
    userRole,
    customerId,
    fetchOrders,
    data,
    isLoading,
    brandType,
    theme,
    headerProps,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const { exportButtons, isExportModalVisible, setExportModalVisibility } = useGetExportButtons();

    const canExportOrders = userRole ? ![UserRoles.admin, UserRoles.brandGroup].includes(userRole) : false;

    const [selectedRestaurantId, setSelectedRestaurantId] = useState(
        Number(getParamFromQueryString(history.location.search, 'restaurant')) || 0,
    );

    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, sortBy, columnFilters }: fetchDataProps) => {
            if (token) {
                const sortOrder = convertTableSortToQuerySort(sortBy);
                const filters = convertTableFiltersToQueryFilters(columnFilters);
                fetchOrders({
                    brandId,
                    restaurantIds: getRestaurantsCondition(selectedRestaurantId, userRole, restaurants),
                    token,
                    search: globalFilter,
                    filters,
                    limit: pageSize ?? DEFAULT_PAGE_SIZE,
                    offset:
                        pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
                    order: sortOrder,
                    customerId,
                });
            }
        },
        [token, fetchOrders, brandId, selectedRestaurantId, userRole, restaurants],
    );

    const columns = useMemo(() => getOrdersTableColumnsVM(intl, brandType, !!customerId), [intl, brandType]);
    const rows = useMemo(() => getOrdersTableRowsVM(data?.omnichannelOrders, intl, theme), [data, intl, theme]);

    const handleRestaurantChange = useCallback(
        (restaurantId: number) => {
            setSelectedRestaurantId(restaurantId);
            replaceParamInUrl(history, 'restaurant', String(restaurantId));
        },
        [history],
    );

    const toggleExport = useCallback(() => {
        setExportModalVisibility((open: boolean) => !open);
    }, [setExportModalVisibility]);

    const defaultHiddenColumns = useMemo(() => {
        if (!brandType) return [];
        return getDefaultHiddenColumns(brandType);
    }, [brandType]);

    return (
        <Container>
            <PageHeader title={headerProps.title} subtitle={headerProps.subTitle}>
                {headerProps.showRestaurantSelector && (
                    <RestaurantSelector
                        buttonLabel={
                            selectedRestaurantId
                                ? restaurants?.find((restaurant) => restaurant.restaurantId === selectedRestaurantId)
                                      ?.name || '???'
                                : intl.formatMessage({ id: 'orderList.filters.allRestaurants' })
                        }
                        restaurants={restaurants || []}
                        selected={selectedRestaurantId}
                        onChange={handleRestaurantChange}
                        headerLabel="orderList.filters.restaurantHeader"
                    />
                )}
            </PageHeader>
            {brandType && (
                <Table
                    rows={rows}
                    emptyMessageId="table.noRowsAfterFilter"
                    columns={columns}
                    hasPagination
                    hasUrlNavigation
                    isFilterable
                    isSortable
                    defaultSortBy={defaultSortBy}
                    fetchData={fetchData}
                    fetchDataTotalCount={data?.count}
                    isLoading={isLoading}
                    onRowClickPath={Routes.OrderDetails}
                    toolbarButtons={canExportOrders ? exportButtons : []}
                    columnSelectorId="orderList"
                    defaultHiddenColumns={defaultHiddenColumns}
                />
            )}
            {isExportModalVisible && (
                <ExportModal
                    width={680}
                    onHide={toggleExport}
                    brandId={brandId}
                    restaurantId={selectedRestaurantId}
                    customerId={customerId}
                />
            )}
        </Container>
    );
};

const withOrderListParams = (WrappedComponent: React.ComponentType<OrderListProps>) => {
    return () => {
        const theme = useTheme();
        const token = useSelector(getUserToken);
        const brandId = useSelector(getAssertedUserBrandId);
        const customerId = Number(useParams<{ customerId?: string }>()?.customerId) || undefined;
        const brandType = useSelector(getBrandType);
        const restaurants = useSelector(getUserRestaurants);
        const userRole = useSelector(getUserRole);
        const [fetchOrders, { data, isLoading }] = useGetOrdersMutation();

        return (
            <WrappedComponent
                token={token}
                brandId={brandId}
                restaurants={restaurants}
                userRole={userRole}
                customerId={Number(customerId) || undefined}
                fetchOrders={fetchOrders}
                data={data}
                isLoading={isLoading}
                brandType={brandType}
                theme={theme}
                headerProps={{
                    title: 'orderList.title',
                    subTitle: 'orderList.subtitle',
                    showRestaurantSelector: true,
                }}
            />
        );
    };
};
export default withOrderListParams(OrderList);
