import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';

import { stringifyError } from '@/utils/errors';
import Loader from '@/components/atoms/Loader';
import {
    computeGrantCurrencies,
    computeGrantForQuery,
    computeRestaurantCustomisations,
    formatRestaurantsForQuery,
} from '@/pages/Brand/Grant/GrantForm/GrantRestaurants/utils';
import { useGetGrantQuery, usePutGrantMutation } from '@/services/innovorder/grant/grant.endpoints';
import { getUserToken } from '@/redux/user';
import { getGrant, getGrantRestaurants, updateGrant, updateGrantRestaurants } from '@/redux/grantAndEntranceFee';
import type { Grant as GrantType } from '@/services/innovorder/grant/grant.type';
import { useShift } from '@/hooks/useShift';
import GrantForm from './GrantForm';

interface ParamTypes {
    brandId: string;
    grantId: string;
}

const Grant: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { grantId, brandId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const grant = useSelector(getGrant);
    const grantRestaurants = useSelector(getGrantRestaurants);
    const dispatch = useDispatch();
    const theme = useTheme();

    const [putGrant, { error: putError, isLoading }] = usePutGrantMutation();
    const { data, error } = useGetGrantQuery(
        {
            grantId: parseInt(grantId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const { hasShiftRequirement } = useShift();

    useEffect(() => {
        if (data !== undefined) {
            const computedRestaurants = computeRestaurantCustomisations(data.restaurants, data, hasShiftRequirement);
            dispatch(updateGrant(computeGrantCurrencies(data)));
            dispatch(updateGrantRestaurants(computedRestaurants));
        }
    }, [data, dispatch, hasShiftRequirement]);

    const update = () => {
        putGrant({
            ...computeGrantForQuery(grant),
            brandId: parseInt(brandId, 10),
            grantId: parseInt(grantId, 10),
            token,
            restaurants: formatRestaurantsForQuery(grantRestaurants, data as GrantType, grant, hasShiftRequirement),
        });
    };

    if (data === undefined) {
        return <Loader color={theme.color.primary} withContainer />;
    }

    return (
        <GrantForm
            grant={computeGrantCurrencies(data)}
            error={stringifyError(error) || stringifyError(putError)}
            onSubmit={update}
            loading={isLoading}
        />
    );
};

export default Grant;
