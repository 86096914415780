import { Button } from '@/components/atoms/Button';
import { SvyIcon } from '@/components/savory/SvyIcon';
import styled from '@emotion/styled';

export const BadgeInputButton = styled(Button)`
    all: unset;
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing[2]};
    padding-right: 0;
    &:hover {
        background-color: transparent;
    }
`;
export const SvyIconWithMargin = styled(SvyIcon)`
    all: unset;
    display: inline-block;
    scale: 1.1;
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing[2]};
`;

export const BadgeInputContainer = styled.div`
    grid-column: span 2;
    display: flex;
    gap: 0px;
    align-items: end;
`;
