import React from 'react';
import { useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';

import { StyledInfoParagraph } from './FieldInfo.style';

type UpdateCustomerFormProps = {
    message: string;
    handleRedirectionLinkClick: () => void;
};

const FieldInfo = React.memo(({ handleRedirectionLinkClick, message }: UpdateCustomerFormProps) => {
    const intl = useIntl();

    return (
        <StyledInfoParagraph>
            {message}
            <span onClick={handleRedirectionLinkClick}>{computeText(intl, 'customer.form.here')}</span>
        </StyledInfoParagraph>
    );
});

export default FieldInfo;
