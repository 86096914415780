import { getBrandLowestPosVersion } from '@/redux/brand';
import { getUseShift } from '@/redux/launchDarkly';
import { useSelector } from 'react-redux';
import { isGreaterOrEqual } from './utils';

const shiftVersionReference = '9.4.0';

export const useShift = (): { hasShiftRequirement: boolean } => {
    const useShiftFeatureFlag = useSelector(getUseShift);
    const lowestPosVersion = useSelector(getBrandLowestPosVersion);

    const isAbovePosRequirement = lowestPosVersion ? isGreaterOrEqual(lowestPosVersion, shiftVersionReference) : true;
    const hasShiftRequirement = useShiftFeatureFlag && isAbovePosRequirement;

    return { hasShiftRequirement };
};
