import { Option } from '@/components/atoms/Select';
import { PricingRules } from '@/services/innovorder/pricingRule/pricingRule.type';

export const getPricingRulesOptions = (pricingRules: PricingRules): Option<number>[] => {
    return pricingRules.map(({ code, pricingRuleId }) => {
        return {
            label: code,
            value: pricingRuleId,
        };
    });
};
