import React from 'react';
import { IntlShape } from 'react-intl';
import { Column } from 'react-table';

import { Theme } from '@emotion/react';
import { InputUnitType } from '@/components/atoms/Form/Input';
import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { SelectProps } from '@/components/atoms/Select/Select';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    CompleteGrantRestaurantCustomisations,
    CustomisationStatus,
    GrantRestaurantCustomisations,
    sortedStatusMap,
} from '@/redux/grantAndEntranceFee';
import {
    BaseGrantCreateAttributes,
    BaseRestaurantGrantData,
    Grant,
    GrantPayload,
    GrantStrategy,
    RestaurantGrantCreateAttribute,
    RestaurantGrantPayload,
    ShiftGrantPayloadData,
} from '@/services/innovorder/grant/grant.type';
import { Restaurant } from '@/services/innovorder/restaurant/restaurant.type';
import { emptyStatus, statusOptionMap } from '../../../EntranceFee/EntranceFeeForm/EntranceFeeRestaurants/utils';
import { GrantStrategyMap, GrantStrategyTranslationMap } from '../GrantForm';

export const computeGrantRestaurantTableRowsVM = ({
    restaurants,
    unitType,
    theme,
    intl,
    isCreateMode,
    onChange,
    handleOnConfigure,
}: {
    unitType: InputUnitType;
    restaurants: CompleteGrantRestaurantCustomisations[];
    theme: Theme;
    intl: IntlShape;
    isCreateMode?: boolean;
    onChange: (restaurantId: number, update: Partial<GrantRestaurantCustomisations>) => void;
    handleOnConfigure: ({ restaurantId, name }: { restaurantId: number; name: string }) => void;
}): TableRow[] => {
    return restaurants.map(
        ({ status, name, restaurantId, amount, dailyLimit, strategy, threshold, ceiling, shifts }) => {
            const baseData: TableRow = {
                status: {
                    type: 'badge',
                    value: {
                        backgroundColor: theme.savory.colors.secondary[200],
                        text: `grant.table.state.${status}`,
                        textColor: 'black',
                        fontWeight: 'bold',
                    },
                },
                oldStatus: {
                    type: 'select',
                    value: {
                        value: status,
                        options: statusOptionMap,
                        onChange: (value) => onChange(restaurantId, { status: value }),
                    } as SelectProps<CustomisationStatus>,
                },
                restaurant: {
                    type: 'string',
                    value: {
                        text: name,
                        opacity: status === 'disabled' ? 0.4 : 1,
                    },
                },
                editAction: {
                    type: 'action',
                    value: {
                        children: computeText(intl, 'button.configure'),
                        buttonType: 'red',
                        hasBorder: true,
                        disabled: !!isCreateMode,
                        onClick: () => handleOnConfigure({ restaurantId, name }),
                    },
                },
            };

            if (emptyStatus.includes(status)) {
                return {
                    ...baseData,
                    shifts:
                        status === 'enabled'
                            ? {
                                  type: 'string',
                                  value: {
                                      text: shifts,
                                  },
                              }
                            : {
                                  type: 'empty',
                                  value: '',
                              },
                    amount: {
                        type: 'empty',
                        value: '',
                    },
                    dailyLimit: {
                        type: 'empty',
                        value: '',
                    },
                    strategy: {
                        type: 'empty',
                        value: '',
                    },
                    oldDailyLimit: {
                        type: 'empty',
                        value: '',
                    },
                    oldStrategy: {
                        type: 'empty',
                        value: '',
                    },
                    ceiling: {
                        type: 'empty',
                        value: '',
                    },
                    threshold: {
                        type: 'empty',
                        value: '',
                    },
                };
            }

            return {
                ...baseData,
                shifts: {
                    type: 'string',
                    value: {
                        text: shifts,
                    },
                },
                amount: {
                    type: 'input',
                    value: {
                        unitType,
                        value: amount?.toString(),
                        type: 'number',
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(restaurantId, { amount: parseFloat(event.target.value) || 0 }),
                    },
                },
                dailyLimit: {
                    type: 'string',
                    value: {
                        text: dailyLimit?.toString(),
                    },
                },
                strategy: {
                    type: 'string',
                    value: {
                        text: GrantStrategyTranslationMap[strategy],
                    },
                },
                oldDailyLimit: {
                    type: 'input',
                    value: {
                        value: dailyLimit?.toString(),
                        type: 'number',
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(restaurantId, { dailyLimit: parseFloat(event.target.value) || 0 }),
                    },
                },
                oldStrategy: {
                    type: 'select',
                    value: {
                        value: strategy,
                        options: GrantStrategyMap,
                        onChange: (value) => onChange(restaurantId, { strategy: value }),
                    } as SelectProps<GrantStrategy>,
                },
                ceiling: {
                    type: 'input',
                    value: {
                        unitType: 'currency',
                        value: ceiling?.toString(),
                        type: 'number',
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(restaurantId, { ceiling: parseFloat(event.target.value) || undefined }),
                    },
                },
                threshold: {
                    type: 'input',
                    value: {
                        unitType: 'currency',
                        value: threshold?.toString(),
                        type: 'number',
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(restaurantId, { threshold: parseFloat(event.target.value) || undefined }),
                    },
                },
            };
        },
    );
};

export const computeGrantRestaurantTableColumnsVM = (intl: IntlShape, useShift: boolean): readonly Column<TableRow>[] =>
    useShift
        ? [
              {
                  Header: computeText(intl, 'grant.table.state'),
                  accessor: 'status',
                  disableSortBy: true,
                  minWidth: 300,
              },
              {
                  Header: computeText(intl, 'grant.table.restaurant'),
                  accessor: 'restaurant',
              },
              {
                  Header: computeText(intl, 'grant.table.services'),
                  accessor: 'shifts',
                  disableSortBy: true,
                  minWidth: 220,
              },
              {
                  Header: computeText(intl, 'grant.table.strategy'),
                  accessor: 'strategy',
                  disableSortBy: true,
                  minWidth: 180,
              },
              {
                  Header: computeText(intl, 'grant.table.dailyLimit'),
                  accessor: 'dailyLimit',
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'grant.table.action'),
                  accessor: 'editAction',
                  disableSortBy: true,
              },
          ]
        : [
              {
                  Header: computeText(intl, 'switch.activate'),
                  accessor: 'oldStatus',
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'grant.table.restaurant'),
                  accessor: 'restaurant',
                  maxWidth: 200,
              },
              {
                  Header: computeText(intl, 'grant.strategy'),
                  accessor: 'oldStrategy',
                  minWidth: 250,
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'grant.amount'),
                  accessor: 'amount',
                  minWidth: 130,
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'grant.table.threshold'),
                  accessor: 'threshold',
                  minWidth: 130,
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'grant.table.ceiling'),
                  accessor: 'ceiling',
                  minWidth: 130,
                  disableSortBy: true,
              },
              {
                  Header: computeText(intl, 'grant.table.dailyLimit'),
                  accessor: 'oldDailyLimit',
                  minWidth: 130,
                  disableSortBy: true,
              },
          ];

export const generateDefaultRestaurantGrant = (
    { amount, dailyLimit, strategy, ceiling, threshold }: BaseGrantCreateAttributes,
    { name, restaurantId }: Restaurant,
    shiftGrants: ShiftGrantPayloadData[] = [],
    defaultShiftName = '',
): CompleteGrantRestaurantCustomisations => {
    return {
        name,
        restaurantId,
        amount,
        dailyLimit,
        strategy,
        ceiling,
        threshold,
        status: 'disabled',
        shifts: shiftGrants.map(({ shift }) => (shift.isDefault ? defaultShiftName : shift.name)).join(', '),
    };
};

export const formatRestaurantGrantDataForQuery = ({
    amount,
    ceiling,
    threshold,
    ...data
}: BaseRestaurantGrantData): BaseRestaurantGrantData => {
    return {
        ...data,
        amount: amount ? amount * CURRENCY_RATE : 0,
        ceiling: ceiling ? ceiling * CURRENCY_RATE : undefined,
        threshold: threshold ? threshold * CURRENCY_RATE : undefined,
    };
};

export const computeRestaurantGrantData = ({
    amount,
    ceiling,
    threshold,
    ...data
}: BaseRestaurantGrantData): BaseRestaurantGrantData => {
    return {
        ...data,
        amount: amount ? amount / CURRENCY_RATE : 0,
        ceiling: ceiling ? ceiling / CURRENCY_RATE : undefined,
        threshold: threshold ? threshold / CURRENCY_RATE : undefined,
    };
};

export const generateRestaurantGrant = (
    customisation: GrantRestaurantCustomisations,
    grant: BaseGrantCreateAttributes,
    shiftGrants: ShiftGrantPayloadData[] = [],
    defaultShiftName = '',
): CompleteGrantRestaurantCustomisations => {
    return {
        amount: grant.amount,
        dailyLimit: grant.dailyLimit,
        strategy: grant.strategy,
        ceiling: grant.ceiling,
        threshold: grant.threshold,
        shifts: shiftGrants.map(({ shift }) => (shift.isDefault ? defaultShiftName : shift.name)).join(', '),
        ...customisation,
    };
};

export const computeEnabledRestaurant = ({
    restaurant,
    enabledRestaurants,
    grant,
    intl,
    hasShiftRequirement,
}: {
    restaurant: Restaurant;
    enabledRestaurants: GrantRestaurantCustomisations[];
    grant: GrantPayload;
    intl: IntlShape;
    hasShiftRequirement: boolean;
}): CompleteGrantRestaurantCustomisations => {
    const enabledRestaurant = enabledRestaurants.find((r) => r.restaurantId === restaurant.restaurantId);
    const shiftGrant = grant.restaurants.find((r) => r.restaurantId === restaurant.restaurantId)?.shiftGrants ?? [];
    const defaultShiftName = computeText(intl, 'shift.default');

    return enabledRestaurant && (hasShiftRequirement ? shiftGrant.length : true)
        ? generateRestaurantGrant(enabledRestaurant, grant, shiftGrant, defaultShiftName)
        : generateDefaultRestaurantGrant(grant, restaurant, shiftGrant, defaultShiftName);
};

export const computeRestaurantsCreateAttributes = ({
    restaurants,
    enabledRestaurants = [],
    grant,
    intl,
    hasShiftRequirement,
}: {
    restaurants: Restaurant[];
    enabledRestaurants?: GrantRestaurantCustomisations[];
    grant?: GrantPayload;
    intl: IntlShape;
    hasShiftRequirement: boolean;
}): CompleteGrantRestaurantCustomisations[] => {
    if (!grant) return [];
    const computedRestaurants = restaurants.map((restaurant) =>
        computeEnabledRestaurant({ restaurant, enabledRestaurants, grant, intl, hasShiftRequirement }),
    );

    return computedRestaurants.sort((a, b) => sortedStatusMap[a.status] - sortedStatusMap[b.status]);
};

const keyToCheck: (keyof BaseRestaurantGrantData)[] = ['amount', 'dailyLimit', 'strategy', 'threshold', 'ceiling'];
const keyToCheckWithShifts: (keyof BaseRestaurantGrantData)[] = ['dailyLimit', 'strategy'];
const shiftKeyToCheck = ['amount', 'threshold', 'ceiling'] as const;

export const isRestaurantCustom = (
    restaurant: BaseRestaurantGrantData,
    grant: BaseGrantCreateAttributes,
    useShift: boolean,
    shiftGrants?: ShiftGrantPayloadData[],
): boolean => {
    const isRestaurantGrantCustom = useShift
        ? keyToCheckWithShifts.some((key) => {
              return grant[key] !== restaurant[key];
          })
        : keyToCheck.some((key) => {
              return grant[key] !== restaurant[key];
          });

    const isShiftGrantCustom = shiftGrants
        ? shiftGrants.some((shiftGrant) =>
              shiftKeyToCheck.some((key) => {
                  return shiftGrant[key] && grant[key] !== shiftGrant[key];
              }),
          )
        : false;

    return isRestaurantGrantCustom || isShiftGrantCustom;
};

export const computeRestaurantCustomisations = (
    restaurants: RestaurantGrantPayload[],
    grant: GrantPayload,
    useShift: boolean,
): GrantRestaurantCustomisations[] => {
    return restaurants.map(({ restaurantId, restaurant_grant, name }) => {
        const shiftGrants = grant.restaurants.find((r) => r.restaurantId === restaurantId)?.shiftGrants;
        const isCustom = isRestaurantCustom(restaurant_grant, grant, useShift, shiftGrants);

        return {
            status: isCustom ? 'customised' : 'enabled',
            ...(isCustom ? computeRestaurantGrantData(restaurant_grant) : {}),
            restaurantId,
            name,
        };
    });
};

export const computeGrantCurrencies = (grant: Grant): Grant => {
    return {
        ...grant,
        amount: grant.amount / CURRENCY_RATE,
        ceiling: grant.ceiling ? grant.ceiling / CURRENCY_RATE : undefined,
        threshold: grant.threshold ? grant.threshold / CURRENCY_RATE : undefined,
    };
};

export const computeGrantForQuery = (grant: BaseGrantCreateAttributes): BaseGrantCreateAttributes => {
    return {
        ...grant,
        code: grant.code.trim(),
        amount: grant.amount * CURRENCY_RATE,
        ceiling: grant.ceiling ? grant.ceiling * CURRENCY_RATE : null,
        threshold: grant.threshold ? grant.threshold * CURRENCY_RATE : null,
        tag: grant.tag || null,
    };
};

export const mapRestaurantCustomisationToCreateAttributes = ({
    restaurantId,
    name,
    ...restaurantData
}: CompleteGrantRestaurantCustomisations): Omit<RestaurantGrantCreateAttribute, 'shiftGrants'> => {
    return {
        restaurantId,
        name,
        restaurant_grant: formatRestaurantGrantDataForQuery(restaurantData),
    };
};

export const formatRestaurantsForQuery = (
    restaurants: GrantRestaurantCustomisations[],
    fetchedGrant: Grant | undefined,
    grant: GrantPayload,
    useShift: boolean,
): RestaurantGrantCreateAttribute[] => {
    return restaurants.map((restaurant) => {
        const computedShiftGrants = useShift
            ? computeAndSpreadShiftGrants({ restaurantId: restaurant.restaurantId, formGrant: grant, fetchedGrant })
            : undefined;

        return {
            ...mapRestaurantCustomisationToCreateAttributes(generateRestaurantGrant(restaurant, grant)),
            shiftGrants: computedShiftGrants,
        };
    });
};

export const computeAndSpreadShiftGrants = ({
    restaurantId,
    fetchedGrant,
    formGrant,
}: {
    restaurantId: number;
    fetchedGrant: Grant | undefined;
    formGrant: GrantPayload;
}): ShiftGrantPayloadData[] => {
    const { shiftGrants } = formGrant.restaurants?.find((r) => r.restaurantId === restaurantId) ?? {
        shiftGrants: [],
    };

    const updatedShiftGrants =
        shiftGrants?.map((shiftGrant) => {
            if (
                fetchedGrant &&
                shiftGrant.shift.isDefault &&
                shiftGrant.amount === fetchedGrant.amount &&
                shiftGrant.threshold === fetchedGrant.threshold &&
                shiftGrant.ceiling === fetchedGrant.ceiling
            ) {
                return {
                    ...shiftGrant,
                    amount: formGrant.amount * CURRENCY_RATE,
                    threshold: formGrant.threshold ? formGrant.threshold * CURRENCY_RATE : null,
                    ceiling: formGrant.ceiling ? formGrant.ceiling * CURRENCY_RATE : null,
                };
            }

            return shiftGrant;
        }) ?? [];

    return updatedShiftGrants;
};

export const udpateEnabledRestaurants = ({
    enabledRestaurants,
    allRestaurants,
    restaurantId,
    updates,
}: {
    enabledRestaurants: GrantRestaurantCustomisations[];
    allRestaurants: Restaurant[];
    restaurantId: number;
    updates: Partial<GrantRestaurantCustomisations>;
}): GrantRestaurantCustomisations[] => {
    if (updates.status === 'disabled') {
        return enabledRestaurants.filter((r) => r.restaurantId !== restaurantId);
    }

    const enabledRestaurant = enabledRestaurants.find((r) => r.restaurantId === restaurantId);

    if (!enabledRestaurant && updates.status) {
        const restaurant = allRestaurants.find((r) => r.restaurantId === restaurantId);
        return [
            ...enabledRestaurants,
            {
                restaurantId,
                name: restaurant?.name || '',
                status: updates.status,
            },
        ];
    }

    if (enabledRestaurant && updates.status === 'enabled') {
        return enabledRestaurants.map((restaurant) => {
            if (restaurant.restaurantId !== restaurantId) {
                return restaurant;
            }

            return {
                restaurantId,
                name: restaurant.name,
                status: 'enabled',
            };
        });
    }

    return enabledRestaurants.map((restaurant) => {
        if (restaurant.restaurantId !== restaurantId) {
            return restaurant;
        }

        return {
            ...restaurant,
            ...updates,
        };
    });
};
